<template>
  <div>
    <section class="bg-signIn d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="py-6 position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-5">
              <div class="card p-4 shadow-sm">
                <form
                  action=""
                  class="border border-1 rounded-2 p-3"
                  @submit.prevent="sendMailToRecoverPassword"
                >
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img
                        src="../../assets/padlock.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 text-center">
                      <h2 class="header-text mt-3 mb-1 text-capitalize">
                        Forgot My Password
                      </h2>
                      <p class="">
                        Enter registered email address / phone number to begin
                        password reset process.
                      </p>
                    </div>

                    <div class="col-lg-12 mt-5">
                      <h6 class="form-label">Email Address/Phone Number</h6>
                      <input
                        type="email"
                        placeholder="Enter your email address or phone number"
                        class="form-control p-3"
                        v-model="forgotPassword.email"
                        required
                      />

                      <FormError :errorMessage="formErrors.email" />
                    </div>

                    <div class="col-12 mt-4">
                      <p>
                        <button
                          class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                          type="submit"
                        >
                          <span>Reset My Password</span>
                          <div>
                            <img
                              src="../../assets/arrow-right.svg"
                              alt=""
                              width="40"
                              height="40"
                              class="img-fluid"
                              v-if="!loading"
                            />
                            <div
                              class="spinner-grow text-white"
                              role="status"
                              v-else-if="loading"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </button>
                      </p>
                      <p>
                        <router-link
                          :to="{ name: 'UserLogin' }"
                          class="text-decoration-none text-body-secondary ms-2"
                        >
                          <img
                            src="../../assets/back.png"
                            alt=""
                            class="img-fluid me-2 mb-1"
                          />Back</router-link
                        >
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

import FormError from "@/components/FormError.vue";

const loading = ref(false);
const forgotPassword = ref({
  email: "",
});

const formErrors = ref({});

const router = useRouter();

function sendMailToRecoverPassword() {
  formErrors.value = {};

  loading.value = true;
  axios
    .post(`/forgot-password`, forgotPassword.value)
    .then((response) => {
      loading.value = false;
      utils.showToast("Success", "success");
      router.push({ name: "Otp", query: {email:forgotPassword.value?.email } });
    })
    .catch((error) => {
      loading.value = false;
      catchAxiosExceptions(error);
      formErrors.value = catchAxiosExceptions(error).formErrors;

      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}
</script>

<style scoped>
/* *{
  outline: 1px solid red;
} */
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.signInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight: 900;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
}
</style>
