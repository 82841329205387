<template>
  <div class="container">
    <Modal :show="showDeleteConfirmation" width="500">
      <template #content>
        <div
          class="d-flex justify-content-center flex-column position-relative"
        >
          <img
            src="@/assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click="abort"
          />
          <div class="d-flex justify-content-center">
            <img
              src="@/assets/success.png"
              alt=""
              class="img-fluid"
              width="100"
            />
          </div>
          <p class="text-center mt-3 header-text2 mb-0">
            Are you sure you want to delete your profile?
          </p>

          <p
            class="text-center d-block d-md-flex justify-content-center mt-3 w-50 mx-auto"
          >
            <button
              class="btn updated btn-lg text-capitalize font-weight-bold w-100"
              @click.prevent="deleteProfile"
            >
              Yes, Proceed
            </button>
          </p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Modal from "@/components/Modal.vue";

const showDeleteConfirmation = ref(true);

const route = useRoute();
const router = useRouter();
let token = ref(null);

onMounted(() => {
  token.value = route.query.token;
});

function abort() {
  router.push({ name: "home" });
  showDeleteConfirmation.value = false;
}

const deleteProfile = async () => {
  if (token.value) {
    try {
      // Replace with your actual API endpoint
      const response = await fetch(
        "https://api.propheticprayerdepot.org/api/me/delete",
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token.value}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete profile");
      }

      router.push({ name: "home" });
      // Handle successful deletion
    } catch (error) {
      console.error("Error deleting profile:", error);
      // Handle error appropriately
    }
  }
};
</script>

<style scoped>
.btn-accent {
  background-color: rgba(255, 225, 225, 1) !important;
  color: rgba(215, 9, 9, 1);
  font-weight: 400 !important;
  border: none;
  font-size: 1rem !important;
  padding: 0.5rem 1.2rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.6rem 1rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: rgba(242, 76, 53, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
