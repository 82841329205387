<template>
  <div>
    <section class="bg-signIn d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="py-6 position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-5">
              <div class="card p-4 shadow-sm">
                <form action="" class="border border-1 rounded-2 p-4">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img
                        src="../../assets/sendOtp.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="col-12 text-center">
                      <h2 class="header-text mt-3 mb-1 text-capitalize">
                        Enter OTP
                      </h2>
                      <p class="">
                        Kindly enter the OTP sent to your provided email address
                        to continue
                      </p>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                      <OtpInput
                        :handleOnChange="handleChange"
                        :handleOnComplete="handleComplete"
                        :isDisabled="isDisabled"
                        ref="otpInputRef"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import OtpInput from "@/components/OtpInput.vue";
import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";
import FormError from "@/components/FormError.vue";

const route = useRoute();
const router = useRouter();

const otpInputRef = ref(null);
const isDisabled = ref(false);

const payload = ref({
  email: "",
  otp: "",
});

const handleChange = (otp) => {
  console.log("Received OTP:", otp);
  // Handle OTP update logic here
};
const handleComplete = async (otp) => {
  console.log("Received OT:", otp);
  isDisabled.value = true;
  payload.value.email = route?.query?.email;
  payload.value.otp = otp;

  try {
    await axios.post("/verifyOTP", payload.value);
    utils.showToast("Success", "success");
    router.push({name: 'OtpVerified'})
  } catch (error) {
    utils.showToast(catchAxiosExceptions(error).msg, "error");
     isDisabled.value = false;
     otpInputRef.value?.clearInput();
  }
};
</script>

<style scoped>
/* *{
  outline: 1px solid red;
} */
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.signInBtn2 {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight: 900;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
}
</style>
