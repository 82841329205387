<template>
  <div>
    <section class="first-section">
      <Navbar
        class="nav"
        linkOne="#whoWeAre"
        linkTwo="#testimonies"
        linkThree="#joinUsLive"
        linkFour="#upcomingEvents"
        data-aos="fade-up"
        data-aos-duration="2000"
      />

      <div class="container">
        <div class="container-padding">
          <div class="row" data-aos="fade-down" data-aos-duration="2000">
            <div class="col-12">
              <h1 class="header-text text-center text-white">
                Welcome to Prophetic Prayer Depot -
                <br class="d-none d-lg-block" />Experience the Power of Prayer
              </h1>

              <p class="subheading1 text-center mt-4">
                Join our inter-denominational prayer sessions, Monday to Friday
                at 5 PM, and witness <br class="d-none d-lg-block" />
                divine transformation through worship, impactful teachings,
                intense prayer, deliverance, <br class="d-none d-lg-block" />
                and miracles
              </p>
              <p class="text-center mt-5">
                <router-link
                  class="btn btn-ligh btn-lg text-capitalize font-weight-bold mb-3 mb-md-0 mb-lg-0"
                  :to="{name: 'AdminPrayerMeetings'}"
                >
                  Join our Prayer Meeting
                </router-link>
                <a
                  class="btn btn-primary btn-lg ms-lg-4 ms-md-2 text-capitalize"
                  href="https://apps.apple.com/ng/app/prophetic-prayer-depot/id6739311999"
                  target="_blank"
                >
                  Download our App
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="whoWeAre" id="whoWeAre">
      <div class="container">
        <div class="py-6">
          <div class="row" data-aos="flip-up" data-aos-duration="2000">
            <div class="col-12">
              <img
                src="../assets/african-american-male-friends.png"
                alt=""
                class="img-fluid"
              />
            </div>
            <div class="col-12 mt-5">
              <h2 class="header-text text-white">Who We Are</h2>
              <p class="subheading1" style="font-weight: 400">
                Prophetic Prayer Depot is an inter-denominational prayer
                ministry led by Prophet Akinkumi Adelani, a devoted man of God
                with a heart for leading people into divine encounters through
                prayer. Our online prayer sessions take place every weekday at 5
                PM, drawing believers from all denominations to experience the
                transforming power of God. Each session includes powerful
                worship, life-changing teachings, intense prayer sessions, and
                testimonies of deliverance and miracles. These sessions are a
                blend of powerful worship, inspired teachings, and fervent
                prayers that usher attendees into the presence of God. The
                ministry emphasizes unity in the body of Christ, welcoming
                people from all walks of life to join in seeking God's face and
                experiencing His supernatural power. The sessions are renowned
                for their life-changing impact, as countless testimonies of
                deliverance, healing, and miracles continue to emerge from those
                who partake. With Prophet Akinkumi Adelani's anointed
                leadership, each gathering becomes a divine encounter, leaving
                participants refreshed, empowered, and encouraged in their
                faith. The ministry is a testament to the limitless power of
                prayer and the faithfulness of God to meet His people at their
                point of need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sessionSection">
      <div class="container">
        <div class="py-6">
          <div class="row" data-aos="flip-down" data-aos-duration="2000">
            <div class="col-12 mt-5">
              <h2 class="header-text text-black">
                What Happens During Our Sessions
              </h2>
              <p class="subheading2" style="font-weight: 400">
                Each session at Prophetic Prayer Depot is designed to help you
                encounter God and experience His transformative power. Here's
                what you can expect when you join us
              </p>
            </div>
          </div>

          <div class="row mt-3 g-3">
            <div
              class="col-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div class="card border-0 bg-transparent">
                <img
                  src="../assets/dancing-concert.png"
                  class="card-img-top img-fluid"
                  alt="Concert Pictures"
                />
                <div class="py-3">
                  <h5
                    class="card-title font-weight-bold text-black header-text2"
                  >
                    Worship
                  </h5>
                  <p class="card-text">
                    Uplifting worship that prepares your heart for an encounter
                    with God.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="2100"
            >
              <div class="card border-0 bg-transparent">
                <img
                  src="../assets/man-holding-book.png"
                  class="card-img-top img-fluid"
                  alt="Concert Pictures"
                />
                <div class="py-3">
                  <h5
                    class="card-title font-weight-bold text-black header-text2"
                  >
                    Impactful Teachings
                  </h5>
                  <p class="card-text">
                    Life-changing messages grounded in biblical truth
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="2200"
            >
              <div class="card border-0 bg-transparent">
                <img
                  src="../assets/praying-together.png"
                  class="card-img-top img-fluid"
                  alt="Concert Pictures"
                />
                <div class="py-3">
                  <h5
                    class="card-title font-weight-bold text-black header-text2"
                  >
                    Intense Prayer Sessions
                  </h5>
                  <p class="card-text">
                    Time dedicated to intercession, personal breakthroughs, and
                    corporate prayer.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="2300"
            >
              <div class="card border-0 bg-transparent">
                <img
                  src="../assets/man-kneeling.png"
                  class="card-img-top img-fluid"
                  alt="Concert Pictures"
                />
                <div class="py-3">
                  <h5
                    class="card-title font-weight-bold text-black header-text2"
                  >
                    Deliverance
                  </h5>
                  <p class="card-text">
                    Freedom from spiritual bondage and oppressive forces.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="2400"
            >
              <div class="card border-0 bg-transparent">
                <img
                  src="../assets/man-kissing-stomach.png"
                  class="card-img-top img-fluid"
                  alt="Concert Pictures"
                />
                <div class="py-3 bg-transparent">
                  <h5
                    class="card-title font-weight-bold text-black header-text2"
                  >
                    Breakthroughs
                  </h5>
                  <p class="card-text">
                    Life-changing messages grounded in biblical truth
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-6 col-lg-4"
              data-aos="zoom-in"
              data-aos-duration="2400"
            >
              <div class="card border-0 bg-transparent">
                <img
                  src="../assets/name_-gravity-_AdUs32i0jc-unsplash.jpg"
                  class="card-img-top img-fluid"
                  alt="Purpose Pictures"
                  style="min-height: 32vh"
                />
                <div class="py-3 bg-transparent">
                  <h5
                    class="card-title font-weight-bold text-black header-text2"
                  >
                    Purpose
                  </h5>
                  <p class="card-text">Discover who God made you to be</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="testimoniesSection" id="testimonies">
      <div class="container">
        <div class="py-6">
          <div class="row" data-aos="flip-up" data-aos-duration="2000">
            <div class="col-12 mt-5">
              <h2 class="header-text text-white">
                God is Still Working Miracles - Testimonies from Our Members
              </h2>
              <p class="subheading text-white" style="font-weight: 400">
                Each session at Prophetic Prayer Depot is designed to help you
                encounter God and experience His transformative power. Here's
                what you can expect when you join us
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <Carousel
                :itemsToShow="2.5"
                :wrapAround="true"
                :autoplay="1500"
                ref="carouselOne"
                :breakpoints="breakpoints"
                v-if="testimonies.length > 0"
              >
                <slide
                  v-for="slide in testimonies"
                  :key="slide.id"
                  class="card border-0 bg-semi-transparent border-semi-light p-4 m-3 d-flex align-items-start flex-column justify-content-center"
                >
                  <div class="d-flex align-items-start flex-column">
                    <img
                      :src="
                        slide?.user?.photo
                          ? slide?.user?.photo
                          : require('@/assets/dummy-avatar-logo.svg')
                      "
                      alt=""
                      class="img-fluid"
                      width="80"
                      height="80"
                    />
                  </div>
                  <div>
                    <h5
                      class="text-start font-weight-bold text-white header-text2"
                    >
                      {{ slide?.testimony_category }}
                    </h5>
                    <p class="text-white text-start">
                      {{ slide?.content ? slide?.content : "No Content" }}
                    </p>
                  </div>
                </slide>
                <template #addons>
                  <Pagination />
                  <Navigation />
                </template>
              </Carousel>
              <Carousel
                :itemsToShow="2.5"
                :wrapAround="true"
                :autoplay="1500"
                ref="carouselOne"
                :breakpoints="breakpoints"
                v-else
              >
                <slide
                  v-for="slide in slidesOne"
                  :key="slide.id"
                  class="card border-0 bg-semi-transparent border-semi-light p-4 m-3 d-flex align-items-start flex-column justify-content-center"
                >
                  <div class="d-flex align-items-start flex-column">
                    <img
                      :src="require(`@/assets/${slide.img}.png`)"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div>
                    <h5
                      class="text-start font-weight-bold text-white header-text2"
                    >
                      {{ slide.name }}
                    </h5>
                    <p class="text-white text-start">
                      {{ slide.content }}
                    </p>
                  </div>
                </slide>

                <template #addons>
                  <Pagination />
                  <Navigation />
                </template>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="joinUsLive">
      <div class="container">
        <div class="py-6">
          <div class="row g-3">
            <div
              class="col-12 col-lg-6 d-md-flex align-items-lg-stretch justify-content-md-center"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src="../assets/joinUs.png" alt="" class="img-fluid" />
            </div>
            <div
              class="col-12 col-lg-6 d-flex align-items-center"
              data-aos="zoom-in"
              data-aos-duration="2100"
            >
              <div class="card bg-primary2 p-3 p-lg-5 border-0 shadow-sm">
                <h2 class="header-text text-black">
                  Join Us Live Every Weekday at 5 PM
                </h2>
                <p class="subheading2 mt-3">
                  Prophetic Prayer Depot sessions are held online, Monday to
                  Friday at 5 PM. You can join us from the comfort of your home,
                  your office, or wherever you are. We believe that there is no
                  distance in the spirit - the power of God will meet you
                  wherever you are!
                </p>
                <!-- <p class="mt-4 w-100 w-lg-75">
                  <button
                    class="btn btn-ligh btn-lg text-capitalize font-weight-bold shadow-sm w-100 w-lg-75"
                    type="submit"
                  >
                    Download the Prophetic Prayer App
                  </button>
                </p> -->
                <!-- <p class="w-75"> -->
                <a
                  class="btn btn-primary btn-lg text-capitalize shadow-sm w-100 w-lg-75"
                  href="https://apps.apple.com/ng/app/prophetic-prayer-depot/id6739311999"
                  target="_blank"
                >
                  Download our App
                </a>
                <!-- </p> -->
              </div>
            </div>
          </div>
          <div class="row mt-5 g-3">
            <div
              class="col-12 col-lg-7"
              data-aos="zoom-in"
              data-aos-duration="2100"
            >
              <div class="card bg-primary3 p-3 p-lg-5 border-0 shadow-sm">
                <h2 class="header-text text-black">
                  Join Us Live Every Weekday at 5 PM
                </h2>
                <p class="subheading2 mt-3">
                  Prophetic Prayer Depot sessions are held online, Monday to
                  Friday at 5 PM. You can join us from the comfort of your home,
                  your office, or wherever you are. We believe that there is no
                  distance in the spirit - the power of God will meet you
                  wherever you are!
                </p>
                <p class="mt-4 w-lg-75">
                  <button
                    class="btn btn-ligh btn-lg text-capitalize font-weight-bold shadow-sm w-100"
                    type="submit"
                  >
                    <img
                      src="../assets/playStore.png"
                      alt=""
                      class="img-fluid"
                    />
                  </button>
                </p>
                <!-- <p class=""> -->
                <a
                  class="btn btn-ligh btn-lg text-capitalize shadow-sm w-100 w-lg-75"
                  href="https://apps.apple.com/ng/app/prophetic-prayer-depot/id6739311999"
                  target="_blank"
                >
                  <img src="../assets/appStore.png" alt="" class="img-fluid" />
                </a>
                <!-- </p> -->
              </div>
            </div>
            <div
              class="col-12 col-lg-5 d-md-flex align-items-lg-stretch justify-content-md-center"
              data-aos="zoom-in"
              data-aos-duration="2200"
            >
              <img src="../assets/connected.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-about about">
      <div class="container">
        <div class="py-6">
          <div class="row g-3">
            <div
              class="col-12 col-lg-5 d-flex align-items-stretch"
              data-aos="flip-right"
              data-aos-duration="2000"
            >
              <img
                src="../assets/manOfGod.png"
                alt=""
                class="img-fluid rounded-3"
              />
            </div>
            <div class="col-12 col-lg-7">
              <div class="p-3 border-0 shadow-sm">
                <h2 class="header-text text-white">
                  Meet Prophet Akinkumi Adelani
                </h2>
                <p class="subheading1 mt-3">
                  Prophet Akinkumi Adelani is a seasoned man of God called to
                  minister healing, deliverance, and the prophetic to God's
                  people. Through his ministry, many have encountered divine
                  intervention, experienced spiritual breakthroughs, and
                  witnessed miraculous signs and wonders. Prophet Adelani leads
                  the Prophetic Prayer Depot with a heart of compassion and a
                  passion for empowering believers through prayer.
                </p>
                <p class="mt-4 w-100 w-lg-75">
                  <button
                    class="btn btn-ligh btn-lg text-capitalize font-weight-bold shadow-sm w-100"
                    type="submit"
                  >
                    Learn More About Prophet Adelani
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="recordSection">
      <div class="container">
        <div class="py-6">
          <div class="row" data-aos="flip-down" data-aos-duration="2300">
            <div class="col-12">
              <h2 class="header-text text-center text-white">
                We Are Here to Pray for You
              </h2>
              <p class="subheading1 text-center my-3">
                Do you have a pressing need? Submit your prayer request and join
                us in believing God for a miracle. At<br
                  class="d-none d-lg-block"
                />
                Prophetic Prayer Depot, we are committed to standing with you in
                faith and interceding for your needs.
              </p>
              <p class="text-center mt-5">
                <button
                  class="btn btn-primary btn-lg text-capitalize shadow-sm"
                  type="submit"
                >
                  Submit Your Prayer Request
                </button>
              </p>
            </div>
            <div class="col-12 d-flex justify-content-center mt-5">
              <img src="../assets/cast-vote.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light-2" id="upcomingEvents">
      <div class="container">
        <div class="py-6">
          <div class="row" data-aos="flip-up" data-aos-duration="2000">
            <div class="col-12 mt-5">
              <h2 class="header-text text-black">
                Upcoming Special Events and Prayer Vigils
              </h2>
              <p class="subheading1 text-black" style="font-weight: 400">
                Don't miss out on our special events, night vigils, and
                prophetic services. Keep an eye on this space for updates on our
                upcoming meetings that you can attend in person or online
              </p>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <Carousel
                :items-to-show="1.8"
                :autoplay="2000"
                :wrap-around="true"
                :breakpoints="breakpoints"
                v-if="meetings.length > 0"
              >
                <slide
                  v-for="slide in meetings"
                  :key="slide.id"
                  class="card border-0 mx-2"
                >
                  <div class="d-flex align-items-start flex-column">
                    <img
                      src="../assets/concert2.png"
                      class="card-img-top"
                      alt="..."
                    />
                  </div>
                  <div class="card-body w-100">
                    <h5 class="card-title text-start header-text2">
                      {{ slide?.title }}
                    </h5>
                    <p class="text-start">{{ slide?.recurring_start_time }}</p>
                    <p class="card-text text-start">
                      {{ slide?.description }}
                    </p>
                  </div>
                </slide>
                <template #addons>
                  <Pagination />
                  <Navigation />
                </template>
              </Carousel>
              <Carousel
                :items-to-show="1.8"
                :autoplay="2000"
                :wrap-around="true"
                :breakpoints="breakpoints2"
                v-else
              >
                <template #slides>
                  <Slide class="">
                    <div class="card">
                      <img
                        src="../assets/concert2.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-start header-text2">
                          Sunday Worship Service
                        </h5>
                        <p class="text-start">10:30AM</p>
                        <p class="card-text text-start">
                          Join us for a time of worship, prayer, and a message
                          from Meet Prophet Akinkumi Adelani
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide class="">
                    <div class="card">
                      <img
                        src="../assets/girl-with-mic.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-start header-text2">
                          Church Choir Anniversary
                        </h5>
                        <p class="text-start">10:30AM</p>
                        <p class="card-text text-start">
                          Join us for a time of worship, prayer, and a message
                          from Meet Prophet Akinkumi Adelani
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide class="">
                    <div class="card">
                      <img
                        src="../assets/concert2.png"
                        class="card-img-top"
                        alt="..."
                      />
                      <div class="card-body">
                        <h5 class="card-title text-start header-text2">
                          Sunday Worship Service
                        </h5>
                        <p class="text-start">10:30AM</p>
                        <p class="card-text text-start">
                          Join us for a time of worship, prayer, and a message
                          from Meet Prophet Akinkumi Adelani
                        </p>
                      </div>
                    </div>
                  </Slide>
                </template>

                <template #addons>
                  <Navigation> </Navigation>
                </template>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

import axios from "@/Plugins/axios";

const testimonies = ref([]);
const meetings = ref([]);

function getTestimonies() {
  axios
    .get(`/guest-testimonies`)
    .then((response) => {
      testimonies.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
      //  utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function getMeetings(page = 1) {
  axios
    .get(`/prayer-meetings?page=${page}`)
    .then((response) => {
      meetings.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

onMounted(() => {
  getTestimonies();
  getMeetings();
});


const slidesOne = ref([
  {
    id: 1,
    img: "avatar1",
    name: "Daniel. E",
    content:
      "I had been battling with a long-standing illness for over 5I had been battling with a long-standing illness for over 5 years. During one of the Prophetic Prayer Depot sessions,Prophet Akinkumi Adelani declared healing over the sick. I felt the power of God, and from that day, I've been completely healed! Glory to God!",
  },
  {
    id: 2,
    name: " Sarah O.",
    img: "avatar2",
    content:
      " I was on the verge of losing my job and was in deep financial distress. During an intense prayer session, Prophet Adelani led a prayer for financial breakthroughs. Within a week, not only did I keep my job, but I received a promotion! God is truly faithful.",
  },
  {
    id: 3,
    name: "Patricia M.",
    img: "avatar3",
    content:
      " My family was under constant spiritual attack. After attending the prayer sessions and receiving deliverance prayers from Prophet Adelani, we've experienced peace in our home like never before. God is truly in this place.",
  },
]);

const breakpoints = ref({
  0: {
    itemsToShow: 0.6,
    snapAlign: "start",
  },
  700: {
    itemsToShow: 2,
    snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 2.5,
    snapAlign: "start",
  },
});
const breakpoints2 = ref({
  0: {
    itemsToShow: 0.6,
    snapAlign: "start",
  },
  700: {
    itemsToShow: 3,
    snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 1.8,
    snapAlign: "start",
  },
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.first-section {
  background-image: url("../assets/photorealistic-style-clouds-stairs2.jpg");
  background-color: rgba(5, 0, 33, 0.3);
  background-position: center;
  background-blend-mode: multiply;
  min-height: 50vh;
}
.whoWeAre {
  background-color: #161b2e;
  background-image: url("../assets/Dots.png");
  background-position: center;
}

.about {
  background-image: url("../assets/Dots.png");
  background-position: center;
}

.sessionSection {
  background-color: rgba(240, 247, 255, 1);
}

.recordSection {
  background-image: url("../assets/Cubes.png");
  background-color: rgba(4, 7, 16, 1);
  background-blend-mode: multiply;
}

.testimoniesSection {
  background-image: url("../assets/testimoniesBg.png");
  background-color: rgba(5, 0, 33, 0.3);
  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}

.container-padding {
  padding: 15rem 0;
}

.header-text {
  font-weight: 900;
  font-size: 3rem;
}
.header-text2 {
  font-weight: 800;
  font-size: 1.6rem;
}

.pt-6 {
  padding-top: 4rem;
}

.py-6 {
  padding: 6rem 0;
}

.subheading1 {
  color: rgba(255, 255, 255, 1);
}
.subheading2 {
  color: rgba(6, 17, 5, 1);
}

.carousel :deep(.carousel__prev),
.carousel :deep(.carousel__next) {
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.818);
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 6rem;
  padding: 1rem;
  color: #131313;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* .carousel >>>.carousel__prev{
  bottom: 6rem;
} */
</style>
