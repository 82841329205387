<!-- components/NavbarToggler.vue -->
<template>
  <button
    class="navbar-toggler"
    type="button"
    ref="togglerRef"
    :aria-controls="targetId"
   
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { Collapse } from 'bootstrap';

export default {
  name: 'NavbarToggler',
  props: {
    targetId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const togglerRef = ref(null);
    let collapse = null;

    onMounted(() => {
      const target = document.getElementById(props.targetId);
      if (target) {
        collapse = new Collapse(target, {
          toggle: false
        });
        togglerRef.value.addEventListener('click', () => {
          collapse.toggle();
        });
      }
    });

    onBeforeUnmount(() => {
      if (collapse) {
        collapse.dispose();
      }
    });

    return {
      togglerRef
    };
  }
};
</script>
<style scoped>
.navbar-toggler-icon {
  background-image: url("../assets/list.svg") !important;
}

.navbar-toggler {
  outline: none !important;
  border: 1px solid #fff !important;
  /* background-color: #fff !important; */
}
</style>