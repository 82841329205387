<template>
  <div>
    <AdminLayout Title="Prayer Meetings" :isRequired="false">
      <TableSkeletonLoader class="mt-3 pt-6" v-if="loading" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminPrayerMeetings' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div
              class="p-4 position-relative home3 rounded-bottom-0 rounded-top-3"
              style="cursor: pointer"
            >
              <div class="position-absolute" style="right: 3rem; top: 2rem">
                <!-- <img src="../../assets/devot-edit.svg" alt="" /> -->
                <img
                  src="../../assets/devot-delete.svg"
                  @click.prevent="showDeleteConfirmation = true"
                   v-if="store?.user?.user_type == 'super_admin'"
                  alt=""
                />
              </div>
              <div class="text-white py-3">
                <h4 class="fw-semibold text-capitalize">Prayer Meetings</h4>

                <!-- <p class="card-text">
                  <img src="../../assets/location-minus.png" alt="" />

                  <span class="text-capitalize">Google Meet</span>
                </p> -->
              </div>
            </div>
            <div class="bg-white rounded-bottom-1 px-3 b-color">
              <p class="fw-semibold mt-4 mb-1 header-text3">
                {{ prayerDetail?.title ?? "No Title" }}
              </p>
              <p>
                {{ prayerDetail?.description ?? "No Description" }}
              </p>
              <!-- <p class="mt-3 fs-sm fw-medium mb-0">
               {{ prayerDetail?.duration }} minutes
              </p> -->
              <p class="d-flex align-items-center">
                <img
                  src="../../assets/calendar-2b.svg"
                  alt=""
                  class="img-fluid me-2"
                />
                <span>
                  {{ prayerDetail?.recurring_start_time ?? "No Time " }}
                </span>
              </p>

              <div class="mt-3 mb-4 d-flex justify-content-between">
                <div>
                  <span class="fw-medium fs-5">Meeting link -</span>

                  <i
                    class="bi bi-copy ms-3 text-primary pointer-events -webkit-bold-icon"
                    @click.prevent="copyMeetingLink"
                  ></i>
                </div>

                <p v-if="store?.user?.user_type == 'super_admin'">
                  <Tooltip text="Clear past meeting history" placement="top">
                  <button style="background: transparent; border: none" @click.prevent="deleteChannelDocument">
                    Clear History <i class="bi bi-trash3"></i>
                  </button>
                  </Tooltip>
                </p>
              </div>
              <p class="">
                <router-link
                  :to="{
                    name: 'agora',
                    query: { meeting_channel: meeting_channel },
                  }"
                  class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                >
                  <span>Proceed To Meeting</span>

                  <div>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                    />
                  </div>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="showDeleteConfirmation" width="500">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showDeleteConfirmation = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-sign-out.png"
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Delete Prayer Meeting
            </p>
            <p class="text-center mb-0">
              Are you sure you want to delete this meeting?
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="signOutBtn btn-lg text-capitalize ms-2 ms-md-4 text-decoration-none"
                @click.prevent="deletePrayerMeetings"
              >
                Confirm
              </button>
              <button
                class="updated btn-lg text-capitalize ms-2 ms-md-4 text-decoration-none"
                @click.prevent="showDeleteConfirmation = false"
              >
                Cancel and Go back
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="deletedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="deletedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Prayer Meeting Deleted Successfully
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminPrayerMeetings' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="deletedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
import Tooltip from '@/components/ToolTip.vue';

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";
import { useAuthStore } from "@/stores/auth";

import {
  collection,
  doc,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  updateDoc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  deleteField,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "@/config/firebase";

const loading = ref(false);

const store = useAuthStore();

const { id } = useRoute().query;
const { meeting_channel, previous_channel } = useRoute().query;
const router = useRouter();
const showDeleteConfirmation = ref(false);
const deletedSuccessfully = ref(false);

const meetingUrl = ref("");

const prayerDetail = ref({});

async function deleteChannelDocument() {
  try {
    // Reference the document using CHANNEL.value
    const channelDocRefReactions = doc(
      db,
      "meeting_room",
      prayerDetail.value?.channel.toString(),
      "meeting_reactions",
      "meeting_reactions"
    );
    await deleteDoc(channelDocRefReactions);
    const channelDocHands = collection(
      db,
      "meeting_room",
      prayerDetail.value?.channel.toString(),
      "meeting_hands"
    );
    // Get all documents in the collection
    const querySnapshot = await getDocs(channelDocHands);

    // Loop through each document and delete it
    const deletePromises = querySnapshot.docs.map(async (document) => {
      const docRef = doc(db, channelDocHands.path, document.id); // Reference to the document
      await deleteDoc(docRef); // Delete the document
    });

    // Wait for all delete operations to complete
    await Promise.all(deletePromises);

    const guestCollectionRef = doc(
      db,
      "meeting_room",
      prayerDetail.value?.channel.toString(),
      "local_guests",
      "guests"
    );

    await deleteDoc(guestCollectionRef); // Delete the document
    utils.showToast("Success", "success");
  } catch (error) {
    console.error("Error deleting document: ", error);
    utils.showToast(catchAxiosExceptions(error).msg, "error");
  }
}

function getMeetingDetail() {
  loading.value = true;
  axios
    .get(`/prayer-meetings/${meeting_channel}`)
    .then((response) => {
      prayerDetail.value = response.data.data;
      loading.value = false;
      meetingUrl.value = `https://propheticprayerdepot.org/meeting?meeting_channel=${prayerDetail.value?.channel}`;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}

async function copyMeetingLink() {
  try {
    await navigator.clipboard.writeText(meetingUrl.value);
    utils.showToast("successfully copied meeting link", "success");
  } catch (err) {
    utils.showToast(catchAxiosExceptions(err).msg, "error");
  }
}
function deletePrayerMeetings() {
  axios
    .delete(`/admin/prayer-meetings/${id}`)
    .then((response) => {
      showDeleteConfirmation.value = false;
      deletedSuccessfully.value = true;
      setTimeout(() => {
        router.push({ name: "AdminPrayerMeetings" });
      }, 1000);
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  getMeetingDetail();
});
</script>
<style scoped>
/* *{
    outline: 1px solid green;
} */
input,
textarea {
  background-color: #f5fbfc !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.pointer-events {
  cursor: pointer !important;
}

.-webkit-bold-icon {
  -webkit-text-stroke: 2px;
  font-weight: bold;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
.header-text3 {
  font-weight: 800;
  font-size: 1.3rem;
}
</style>
