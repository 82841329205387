<template>
  <div>
    <AdminLayout Title="Prayer Request" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminPrayerRequest' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 g-2">
          <div class="col-12 col-lg-8">
            <div class="bg-card1 p-3 rounded-3">
              <div class="d-flex">
                <div class="ms-3">
                  <h4 class="mb-0 fw-semibold">
                    {{
                      prayerDetail?.conversation?.messages[0]?.user?.first_name
                    }}
                    {{
                      prayerDetail?.conversation?.messages[0]?.user?.last_name
                    }}
                  </h4>
                  <!-- <p class="text-body-secondary mt-0">
                    okakaemmanuel@gmail.com
                  </p> -->
                </div>
              </div>
              <div class="d-flex mt-3 row g-2">
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Submission Date</small>
                    <p class="fw-semibold mt-3">
                      {{ getFormattedDateNumber(prayerDetail?.created_at) }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Deadline</small>
                    <p class="fw-semibold mt-3">N/A</p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary d-block mb-1"
                      >Status</small
                    >
                    <p class="pt-2">
                      <button class="fw-semibold btn-active d-block">
                        {{
                          prayerDetail?.is_answered == false
                            ? "Not Answered"
                            : "Answered"
                        }}
                      </button>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12">
                  <div class="bg-white p-2 rounded-2">
                    <div class="mt-2">
                      <p class="form-label">Prayer Replies/Comments</p>
                      <VerticalTimeLine :timelineEvents="events" class="mt-4" />
                    </div>
                  </div>
                </div>
              </div>

              <p class="mt-4">
                <button
                  class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                  @click.prevent="showActions = true"
                >
                  <span>Provide Actions</span>
                  <div>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                      v-if="!loading"
                    />
                    <div
                      class="spinner-grow text-white"
                      role="status"
                      v-else-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </p>
            </div>
          </div>
          <!-- <div class="col-12 col-lg-6">
            <div class="card p-3 b-color">
              <h4 class="fw-semibold ms-3">Messages</h4>
              <VerticalTimeLine :timelineEvents="events" class="mt-4" />
            </div>
          </div> -->
        </div>
      </div>

      <Modal :show="showActions">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative pb-3"
          >
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="fw-semibold mt-3 mb-0">Provide Actions</h4>
              <p class="fw-light mt-1 text-center text-body-secondary">
                <img
                  src="../../assets/close-circle.png"
                  alt=""
                  class="img-fluid"
                  @click="showActions = false"
                />
              </p>
            </div>
            <div
              class="card p-2 b-color d-flex justify-content-between flex-column mt-4 position-relative"
              style="cursor: pointer"
              @click.prevent="openComment"
            >
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/action-message.png"
                  alt=""
                  class="img-fluid"
                  width="50"
                />
                <span class="fw-semibold ms-2 mt-1">Provide Feedback</span>
              </div>
              <img
                src="../../assets/right-gray.png"
                alt=""
                class="img-fluid justify-self-end position-absolute"
                style="right: 1rem; top: 37%"
                width="9"
              />
            </div>
            <div
              class="card p-2 b-color d-flex justify-content-between flex-column mt-4 position-relative"
              style="cursor: pointer"
              @click.prevent="showMarkAnswered = true"
            >
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/admin-prayerreq2.png"
                  alt=""
                  class="img-fluid"
                  width="50"
                />
                <span class="fw-semibold ms-2 mt-1"
                  >Mark Prayer as Answered</span
                >
              </div>
              <img
                src="../../assets/right-gray.png"
                alt=""
                class="img-fluid justify-self-end position-absolute"
                style="right: 1rem; top: 37%"
                width="9"
              />
              <!-- <div> -->

              <!-- </div> -->
            </div>
            <div
              class="card p-2 b-color bg-delete d-flex justify-content-between flex-column mt-4 position-relative"
              style="cursor: pointer"
              @click.prevent="showDeletePrayer = true"
            >
              <div class="d-flex align-items-center">
                <img
                  src="../../assets/admin-bin.png"
                  alt=""
                  class="img-fluid"
                  width="50"
                />
                <span class="fw-semibold ms-2 mt-1" style="color: #d72509"
                  >Delete this Prayer</span
                >
              </div>
              <img
                src="../../assets/a-delete.png"
                alt=""
                class="img-fluid justify-self-end position-absolute"
                style="right: 1rem; top: 37%"
                width="9"
              />
              <!-- <div> -->

              <!-- </div> -->
            </div>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>

      <Modal :show="showConversationBox">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <form class="w-75 mx-auto">
              <div class="d-flex justify-content-end align-items-center">
                <img
                  src="../../assets/close-circle.png"
                  alt=""
                  class="img-fluid justify-self-end"
                  @click="showConversationBox = false"
                />
              </div>
              <p class="header-text2 mb-1">Comments</p>
              <textarea
                name=""
                id=""
                v-model="conversationDetail"
                class="form-control"
              ></textarea>
              <p class="mt-4">
                <button
                  class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                  @click.prevent="sendMessage"
                >
                  <span>Add Comment</span>
                  <div>
                    <img
                      src="../../assets/arrow-right.svg"
                      alt=""
                      width="40"
                      height="40"
                      class="img-fluid"
                      v-if="!loading3"
                    />
                    <div
                      class="spinner-grow text-white"
                      role="status"
                      v-else-if="loading3"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </button>
              </p>
            </form>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="showMarkAnswered">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showMarkAnswered = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-sign-out.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Mark Prayer as Answered
            </p>
            <p class="fw-light mt-0 text-center">
              Do you want to mark this prayer as answered
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="signInBtn btn-lg text-capitalize font-weight-bold"
                @click.prevent="popMarkAnswered"
              >
                Mark as Answered
              </button>
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                @click.prevent="showMarkAnswered = false"
              >
                Cancel and Go back
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="showDeletePrayer">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showDeletePrayer = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-sign-out.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Delete this Prayer</p>
            <p class="fw-light mt-0 text-center">
              Are you sure you want to delete this particular prayer?
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="signOutBtn btn-lg text-capitalize font-weight-bold"
                @click.prevent="popDeletePrayer"
              >
                Delete Prayer
              </button>
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                @click.prevent="showDeletePrayer = false"
              >
                Cancel and Go back
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="markedSuccessful">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="markedSuccessful = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              This Prayer Has Been Marked as Answered
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                @click.prevent="markedSuccessful = false"
              >
                Back to Home
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import VerticalTimeLine from "@/components/VerticalTimeLine.vue";
import Modal from "@/components/Modal.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import {
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const showActions = ref(false);
const showMarkAnswered = ref(false);
const showConversationBox = ref(false);
const showDeletePrayer = ref(false);
const markedSuccessful = ref(false);

const conversationId = ref(0);
const conversationDetail = ref("");
const loading3 = ref(false);

const { id } = useRoute().params;

const events = ref([]);

const prayerDetail = ref({});

const loading = ref(false);
const loading2 = ref(false);

function openComment() {
  showActions.value = false;
  showConversationBox.value = true;
}

function sendMessage() {
  loading3.value = true;
  axios
    .post(`/conversations/${conversationId.value}/messages`, {
      message: conversationDetail.value,
    })
    .then((response) => {
      utils.showToast("Successful", "success");
      getPrayerRequestDetails();
      loading3.value = false;
      conversationDetail.value = "";
      showConversationBox.value = false;
    })
    .catch((error) => {
      utils.showToast("Error sending message", "error");
      loading3.value = false;
    });
}

function popMarkAnswered() {
  axios
    .post(`/prayer-points/${id}/mark-as-answered`)
    .then((response) => {
      showMarkAnswered.value = false;
      showActions.value = false;
      getPrayerRequestDetails();
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}
function popDeletePrayer() {
  axios
    .delete(`/prayer-points/${id}`)
    .then((response) => {
      showDeletePrayer.value = true;
      showActions.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function getPrayerRequestDetails() {
  loading2.value = true;
  axios
    .get(`/admin/prayer-points/${id}`)
    .then((response) => {
      prayerDetail.value = response.data.data;
      events.value = prayerDetail.value?.conversation?.messages;

      conversationId.value = prayerDetail.value?.conversation?.id;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

onMounted(() => {
  getPrayerRequestDetails();
});
</script>
<style scoped>
/* *{
    outline: 1px solid red;
} */
.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
.pt-6 {
  padding-top: 6rem !important;
}
.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.bg-delete {
  background-color: #fff4f4 !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}
</style>
