<template>
  <div>
    <UserLayout>
      <TableSkeletonLoader class="pt-6" v-if="loading" />

      <section class="bg-signIn d-flex justify-content-center" v-else>
        <div class="container">
          <div class="py-6 position-relative">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-10">
                <div>
                    <router-link
                      :to="{ name: 'UserCounselling' }"
                      class="text-decoration-none text-body-secondary ms-2 "
                    >
                      <img
                        src="../../assets/back.png"
                        alt=""
                        class="img-fluid me-2 mb-1"
                      />Back</router-link
                    >
                  </div>
                <h3 class="header-text mt-3">Counselling</h3>
              </div>

              <div class="col-12 col-lg-10 mt-3">
                <div class="card b-color p-5">
                  <div class="row">
                    <div v-if="counselling.length == 0" class="mt-5">
                      No Counselling Session
                    </div>
                   
                    <div class="col-12">
                      <p v-if="bookedCounselling.length == 0">No Booked Sessions</p>
                        <div
                      class="col-12 col-lg-6"
                      v-for="(session,index) in bookedCounselling"
                      :key="index"
                    >
                   <div class="position-relative rounded-3 border mt-3">
                        <div
                          class="d-flex justify-content-between align-items-center px-3 pt-3 pb-3"
                        >
                          <span class="fw-semibold text-dark">{{
                            session?.title
                          }}</span>
                          <button class="btn-active">
                            {{ session?.status }}
                          </button>
                        </div>
                        <hr class="" />
                        <div class="row px-2">
                          
                          <div class="col-12 col-lg-6">
                            <div>
                              <span
                                class="text-body-secondary"
                                style="font-size: 0.9rem"
                                >Session Period</span
                              >
                              <p
                                class="fw-semibold text-dark"
                                style="font-size: 0.93rem"
                              >
                                {{ formatTo12Hour(session?.start_time) }} -
                                {{ formatTo12Hour(session?.end_time) }}
                              </p>
                            </div>
                          </div>

                          <div class="col-12 col-lg-6">
                            <div>
                              <span
                                class="text-body-secondary"
                                style="font-size: 0.9rem"
                                >Session Date</span
                              >
                              <p
                                class="fw-semibold text-dark"
                                style="font-size: 0.93rem"
                              >
                                {{ getFormattedDate(session?.session_date) }}
                              </p>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <div>
                              <span
                                class="text-body-secondary"
                                style="font-size: 0.9rem"
                                >Session Time</span
                              >
                              <p
                                class="fw-semibold text-dark"
                                style="font-size: 0.93rem"
                              >
                                {{ session?.session_duration }} Minutes
                              </p>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                  </div>
                    </div>



                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal :show="showAvailableSessions" width="550">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click="closeModal"
            />

            <h3>Select Available Sessions</h3>
            <MultiSelectSessionButtons
              v-model="selectedOptions"
              :options="availableSlots"
            />

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <!-- <router-link
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-50 mx-auto"
                :to="{ name: 'UserDashboard' }"
              >
                View Dashboard
              </router-link> -->
              <button
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                @click.prevent="
                  bookSession(selectedOptions?.id, selectedOptions?.user_id)
                "
              >
                <span>Proceed</span>
                <div>
                  <img
                    src="../../assets/arrow-right.svg"
                    alt=""
                    width="40"
                    height="40"
                    class="img-fluid"
                    v-if="!loading2"
                  />
                  <div
                    class="spinner-grow text-white"
                    role="status"
                    v-else-if="loading2"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import Modal from "@/components/Modal.vue";

import UserLayout from "@/components/Layouts/UserLayout.vue";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import MultiSelectSessionButtons from "@/components/MultiSelectSessionButtons.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const counselling = ref([]);

const bookedCounselling = ref([]);

const loading = ref(false);
const loading2 = ref(false);
const loading3 = ref(false);

const showAvailableSessions = ref(false);

const availableSlots = ref([]);

const selectedOptions = ref({});

async function initCounselling() {
  loading.value = true;
  try {
    const _counsellingData = await axios.get(`/counselling-sessions`);
    
    const userDetails = JSON.parse(localStorage.getItem("user"));
    counselling.value = _counsellingData.data.data;
   
    for(let i = 0; i < counselling.value.length; i++) {
      for(let j = 0; j < counselling.value[i].slots.length; j++) {
       
          if(counselling.value[i].slots[j].user_id == userDetails?.id){
            bookedCounselling.value.push(counselling.value[i]);
          }
       }
    }
    loading.value = false;
  } catch (error) {
    utils.showToast(catchAxiosExceptions(error).msg, "error");
    loading.value = false;
  }
}

function getAvailableSlots(id) {
  loading3.value = true;
  axios
    .get(`/counselling-sessions/${id}/available-slots`)
    .then((response) => {
      loading3.value = false;
      showAvailableSessions.value = true;

      availableSlots.value = response.data.data;
    })
    .catch((error) => {
      loading3.value = false;
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function bookSession(id, userId) {
  loading2.value = true;

  axios
    .put(`/counseling-slots/${id}`, {
      user_id: userId,
    })
    .then((response) => {
      loading2.value = false;
      showAvailableSessions.value = false;
      utils.showToast("Counselling Session Reserved", "success");
      initCounselling();
    })
    .catch((error) => {
      loading2.value = false;
      showAvailableSessions.value = false;

      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function formatTo12Hour(dateString) {
      // Create a Date object from the given string
      const date = new Date(dateString.replace(" ", "T"));

      // Extract hours and minutes
      let hours = date.getHours();
      const minutes = date.getMinutes();

      // Determine AM/PM
      const period = hours >= 12 ? "pm" : "am";

      // Convert to 12-hour format
      hours = hours % 12 || 12; // If 0, set to 12

      // Format minutes with leading zero if needed
      const formattedMinutes = minutes.toString().padStart(2, "0");

      // Return formatted time
      return `${hours}:${formattedMinutes}${period}`;
    }
function closeModal() {
  selectedOptions.value = {};
  showAvailableSessions.value = false;
}

onMounted(() => {
  initCounselling();
});
</script>

<style scoped>
.bg-dropbox {
  background-color: rgba(245, 251, 252, 1);
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.btn-primary {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  padding: 0.4rem 0.6rem;
  border-radius: 9px;
  border: none;
  position: relative;
  overflow: hidden;
}

.btn-primary:hover {
  background-color: rgba(10, 64, 194, 0.8) !important;
  box-shadow: 0 4px 8px rgba(10, 64, 194, 0.3) !important;
  transform: translateY(-4px);
}

.b-color {
  border: 1px solid #ddedff !important;
}

.py-6 {
  padding: 7rem 0;
}

.header-text {
  font-weight: 700;
  font-size: 2rem;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}
.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
</style>
