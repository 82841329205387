<template>
  <div>
    <section class="bg-signIn d-flex justify-content-center align-items-center">
      <div class="container">
        <div class="py-6 position-relative">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-7">
              <div class="card p-3 shadow-sm">
                <form action="" class="border border-1 rounded-2 p-3">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <img
                        src="../../assets/logoP.jpg"
                        alt=""
                        class="img-fluid"
                      />
                      <div class="mt-4">
                        <nuxt-link 
                        to="/accounts/signup">

                        <img
                          src="../../assets/back.svg"
                          alt=""
                          width="30"
                          class="img-fluid"
                        />
                      </nuxt-link>
                      </div>
                      <h2 class="header-text mt-3">Enter your birthday</h2>
                      <p class="mt-0">
                        A birthday just isn't a birthday without a special gift
                        of prayers and offering to God
                      </p>
                    </div>
                    <div class="col-12 col-md-6">
                      <h6 class="form-label">Month and Year</h6>
                      <input
                        type="month"
                        placeholder="Select Month"
                        class="form-control p-3"
                      />
                    </div>
                    <div class="col-12 col-md-6">
                      <h6 class="form-label">Day</h6>
                      <select name="" id="" class="form-select p-3">
                       <option value="Sunday">Sunday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                     </select>
                    </div>
                   
                  
                    <div class="col-12 mt-5">
                      <p>
                        <button
                          class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                          @click.prevent="nextStep"
                        >
                          <span>Proceed</span>
                         <div>
                            <img
                              src="../../assets/arrow-right.svg"
                              alt=""
                              width="40"
                              height="40"
                              class="img-fluid"
                              v-if="!loading"
                            />
                            <div
                              class="spinner-grow text-white"
                              role="status"
                              v-else-if="loading"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </button>
                      </p>
                      <p>
                        <span style="color: rgba(170, 170, 170, 1)"
                          >Already have an account?</span
                        ><nuxt-link
                          to="/accounts/login"
                          class="text-decoration-none"
                          style="color: rgba(6, 17, 5, 1)"
                        >
                          Sign In</nuxt-link
                        >
                      </p>
                      <!-- <VueTelInput
                        v-model="phone"
                        class="p-2"
                        v-bind="bindProps"
                      /> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import {ref} from "vue"

const loading = ref(false);

function nextStep() {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    navigateTo('/accounts/welcomeaftersignup');
  }, 3000);
}

</script>

<style scoped>
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text {
  font-weight:900;
  font-size: 2.3rem;
}

@media screen and (max-width: 767px) {
  .signInBtn {
    background-color: rgba(10, 64, 194, 1) !important;
    font-weight: 600 !important;
    border: none;
    color: #fff !important;
    font-size: 1rem !important;
    padding: 1rem 1.5rem;
    border-radius: 9px;
  }
  .boy-singing {
    display: none;
  }

  .youngWoman {
    display: none;
  }
  .oldMan {
    display: none;
  }

  .prayingMan {
    display: none;
  }
  .oldWoman {
    display: none;
  }
}
</style>
