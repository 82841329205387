<!-- components/Dropdown.vue -->
<template>
  <div class="dropup">
    <button
      class=" dropdown-toggle rounded-circle border-0"
      type="button"
      :id="dropdownId"
      ref="dropdownRef"
      :data-bs-toggle="'dropdown'"
      aria-expanded="false"
    >
     <i class="bi bi-three-dots-vertical fs-4"></i>
    </button>
    <ul class="dropdown-menu" :aria-labelledby="dropdownId">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { Dropdown } from 'bootstrap';

export default {
  name: 'Dropdown',
  props: {
    buttonText: {
      type: String,
      default: 'Dropdown',
    },
    dropdownId: {
      type: String,
      required: true, // Ensure a unique ID is passed for accessibility
    },
  },
  setup() {
    const dropdownRef = ref(null);
    let dropdown = null;

    onMounted(() => {
      // Initialize the Dropdown component
      dropdown = new Dropdown(dropdownRef.value);
    });

    onBeforeUnmount(() => {
      // Dispose of the Dropdown component
      if (dropdown) {
        dropdown.dispose();
      }
    });

    return {
      dropdownRef,
    };
  },
};
</script>
<style scoped>
.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle::before {
  display: none !important;
}

.dropup .dropdown-toggle::after {
  display: none !important;
}
.dropup .dropdown-toggle::before {
  display: none !important;
}
</style>
