<template>
  <div>
    <UserLayout>
      <CardTwoContentLoader class="mt-5" v-if="showLoader" />

      <section class="bg-signIn d-flex" v-else>
        <div class="container">
          <div class="py-6">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-11">
                <div class="mt-3">
                  <!-- <div>
                    <nuxt-link
                      :to="{ name: 'DashboardPrayerRequests' }"
                      class="text-decoration-none text-body-secondary ms-2 "
                    >
                      <img
                        src="../assets/back.png"
                        alt=""
                        class="img-fluid me-2 mb-1"
                      />Back</nuxt-link
                    >
                  </div> -->
                  <h2 class="header-text text-dark mb-0">Recordings</h2>
                </div>
                <div
                  class="card p-card2 b-color p-3 mt-3 shadow-sm"
                  v-if="recordData.length <= 0"
                >
                  <h5 class="text-center text-capitalize fw-medium">
                    No Recordings
                  </h5>
                </div>
                <div
                  class="card p-3 mt-4 shadow-sm b-color"
                  v-for="(data, index) in recordData"
                  :key="index"
                >
                  <div class="card my-3 mt-0">
                    <div class="row g-0">
                      <div class="col-12 col-lg-12">
                        <div class="row" style="max-height: 150px">
                          <div class="col-md-3">
                            <img
                              :src="data?.cover_image"
                              class="img-fluid w-100"
                              v-if="data?.cover_image"
                              style="max-height: 150px"
                              alt="Recordings picture"
                            />
                            <img
                              :src="require('@/assets/woman-on-the-altar.png')"
                              class="img-fluid me-2 w-100 h-75"
                              style="max-height: 100px"
                              v-else
                              alt="Recordings picture"
                            />
                          </div>
                          <div class="col-md-9">
                            <div
                              class="card-body p-2 d-lg-flex d-block justify-content-between align-items-center mt-5"
                            >
                              <div>
                                <h5
                                  class="mt-2"
                                  style="font-size: 1.4rem; font-weight: 600"
                                >
                                  {{ data.title }}
                                </h5>
                                <!-- <p>46:41 minutes</p> -->
                                <div class="d-flex">
                                  <div class="d-flex align-items-center">
                                    <img
                                      src="../../assets/calendar-2b.svg"
                                      alt=""
                                      class="img-fluid mb-3 me-1"
                                      width="20"
                                      height="20"
                                    />
                                    <p>
                                      {{ getFormattedDate(data.created_at) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <!-- <button class="btn btn-lg updated">
                                  Details
                                </button> -->
                                <button
                                  class="btn signInBtn ms-3"
                                  @click.prevent="getSinglRecording(data.id)"
                                >
                                  View Recording
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalMeetings :show="showModal" width="600" class="p-0 rounded-3">
        <template #content>
          <div
            class="card position-relative rounded-pill mb-0 p-0"
            style="cursor: pointer"
          >
            <img
              src="../../assets/close-circle.png"
              class="position-absolute"
              style="right: 0.8rem; top: 0.6rem"
              alt=""
              @click="showModal = false"
            />
            <img
              :src="singleRecordData?.cover_image"
              alt=""
              class="img-fluid"
              style="width: 100; height: 100; top: 0; left: 0; max-height: 180px"
            />
          </div>

          <div>
            <h3 class="px-4 mb-3 pt-3">{{ singleRecordData?.title }}</h3>
            <div class="px-4 mt-3">
              <div class="d-flex">
                <div class="d-flex align-items-center">
                  <img
                    src="../../assets/calendar-2b.svg"
                    alt=""
                    class="img-fluid mb-3 me-1"
                    width="20"
                    height="20"
                  />
                  <p>{{ getFormattedDate(singleRecordData?.created_at) }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-5 position-relative p-4">
            <p class="position-relative">
              <button
                @click.prevent="listenToRecording"
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center shadow-sm"
              >
                <span>Listen to Recordings</span>
                <img
                  src="../../assets/arrow-right.svg"
                  alt=""
                  width="40"
                  height="40"
                  class="img-fluid"
                />
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </ModalMeetings>

      <Modal :show="showRecordings" width="650">
        <template #content>
       
          <div class="position-relative rounded-pill mb-0 p-0">
            <img
              src="../../assets/close-circle.png"
              class="position-absolute"
              style="right: 0.8rem; top: 0.6rem"
              alt=""
              @click="showRecordings = false"
            />
          </div>
          <iframe
            width="560"
            height="315"
            :src="`https://www.youtube.com/embed/${currentLink}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="mt-5"
          ></iframe>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import UserLayout from "@/components/Layouts/UserLayout.vue";
import ModalMeetings from "@/components/ModalMeetings.vue";
import Modal from "@/components/Modal.vue";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import CardTwoContentLoader from "@/components/CardTwoContentLoader.vue";

const showLoader = ref(false);

const showModal = ref(false);
const showRecordings = ref(false);

const recordData = ref([]);

const currentLink = ref(null);

const singleRecordData = ref({});

watch(currentLink, (newVal, oldVal) => {
  currentLink.value = newVal;
});

function getYouTubeVideoId(url) {
 
  const videoId = url?.split("v=")[1];
  // Return the video ID
  return videoId;
}

function listenToRecording() {
  showRecordings.value = true;
  showModal.value = false;
}

async function initRecordings() {
  showLoader.value = TextTrackCue;
  try {
    const _recordData = await axios.get(`/recordings`);

    recordData.value = _recordData.data.data;
    showLoader.value = false;
  } catch (error) {
    showLoader.value = false;

    catchAxiosExceptions(error);
    utils.showToast(catchAxiosExceptions(error).msg, "error");
  }
}

function getSinglRecording(id) {
  showModal.value = true;
  axios
    .get(`recordings/${id}`)
    .then((response) => {
      singleRecordData.value = response.data.data;      
      currentLink.value = getYouTubeVideoId(response.data.data.link) ?? "";
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  initRecordings();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}
.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 0.5rem 1rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 400 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.5rem 0.7rem;
  border-radius: 9px;
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.p-card1 {
  background-color: rgba(248, 248, 248, 1) !important;
}

.p-card2 {
  background-color: rgba(237, 255, 227, 1);
}
</style>
