<template>
  <div>
    <AdminLayout Title="Roles and Permissions" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminRoles' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div class="bg-card1 p-3 rounded-3">
              <div class="d-flex align-items-center">
                <img
                  :src="
                    roleDetail.photo
                      ? roleDetail.photo
                      : require('@/assets/dummy-avatar-logo.svg')
                  "
                  alt=""
                  class="img-fluid"
                  width="80"
                  height="80"
                />
                <div class="ms-3">
                  <h5 class="mb-0">
                    {{ roleDetail?.first_name }} {{ roleDetail?.last_name }}
                  </h5>
                  <p class="text-body-secondary mt-0">
                    {{ roleDetail?.email }}
                  </p>
                </div>
              </div>
              <div class="d-flex mt-3 row g-2">
                <div class="col-12 col-lg-6 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Date Joined</small>
                    <p class="fw-semibold mt-3">
                      {{
                        roleDetail?.created_at
                          ? getFormattedDate(roleDetail?.created_at)
                          : ""
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-6 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Role</small>
                    <p class="fw-semibold mt-3 text-capitalize">
                      {{ formattedWord }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p class="rounded-3 mt-4">
              <button
                class="updated text-center w-100 shadow-sm"
                @click.prevent="showUpdateRole = true"
              >
                Change Role
              </button>
            </p>
            <p class="rounded-3 mt-4">
              <button
                class="remove-btn text-center w-100 shadow-sm"
                @click.prevent="showRemoveAdmin = true"
              >
                Remove this Admin
              </button>
            </p>
          </div>
        </div>
      </div>

      <Modal :show="showRemoveAdmin">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showRemoveAdmin = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-sign-out.png"
                alt=""
                class="img-fluid"
                width="100"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Remove User</p>
            <p class="fw-light mt-0 text-center">
              Are you sure you want to remove user?
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="signOutBtn btn-lg text-capitalize font-weight-bold"
                @click.prevent="removeUser"
              >
                Yes, Remove User
              </button>
              <button
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4"
                @click.prevent="showRemoveAdmin = false"
              >
                Cancel and Go back
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>

      <Modal :show="showUpdateRole">
        <template #content>
          <img
            src="../../assets/close-circle.png"
            alt=""
            class="img-fluid position-absolute"
            style="top: 0; right: 0; cursor: pointer"
            @click.prevent="showUpdateRole = false"
          />
          <form
            action=""
            class="border-1 rounded-2 p-3"
            style="overflow-y: scroll"
          >
            <h3 class="header-text2">Update User</h3>
            <div class="row">
              <div class="col-12">
                <p class="form-label mt-3">First Name</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  placeholder="Enter your first name"
                  v-model="roleDetail.first_name"
                />
                <FormError :errorMessage="formErrors.first_name" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Last Name</p>
                <input
                  type="text"
                  required
                  class="p-2 form-control"
                  v-model="roleDetail.last_name"
                  placeholder="Enter your last Name"
                />
                <FormError :errorMessage="formErrors.last_name" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Email</p>
                <input
                  type="email"
                  required
                  v-model="roleDetail.email"
                  class="p-2 form-control"
                  placeholder="Enter your email address"
                />
                <FormError :errorMessage="formErrors.email" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Phone Number</p>
                <input
                  type="text"
                  required
                  v-model="roleDetail.phone"
                  class="p-2 form-control"
                  placeholder="Enter your current phone number"
                />
                <FormError :errorMessage="formErrors.phone" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Birthday</p>
                <input
                  type="date"
                  required
                  v-model="roleDetail.birthday"
                  class="p-2 form-control"
                />
                <FormError :errorMessage="formErrors.birthday" />
              </div>

              <div class="col-12">
                <p class="form-label mt-3">User Type</p>
                <multiselect
                  v-model="roleDetail.user_type"
                  :options="userTypes"
                  label="name"
                  track-by="key"
                  value-key="key"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :show-no-results="false"
                >
                  <template #noResult>
                    <span
                      >Oops! No User found. Consider changing your search
                    </span>
                  </template>
                </multiselect>
                <FormError :errorMessage="formErrors.status" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Password</p>
                <div class="input-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="********"
                    class="form-control p-2 animated-placeholder"
                    v-model="roleDetail.password"
                    required
                  />
                  <span
                    class="input-group-text"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      :class="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                    ></i>
                  </span>
                </div>
                <FormError :errorMessage="formErrors.password" />
              </div>
              <div class="col-12">
                <p class="form-label mt-3">Password Confirmation</p>
                <div class="input-group">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="********"
                    class="form-control p-2 animated-placeholder"
                    required
                    v-model="roleDetail.password_confirmation"
                  />
                  <span
                    class="input-group-text"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      :class="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                    ></i>
                  </span>
                </div>
                <FormError :errorMessage="formErrors.password_confirmation" />
              </div>

              <div class="col-12 mt-4">
                <p class="">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="updateUserDetail"
                  >
                    <span>Update User</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading2"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading2"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
      <Modal :show="roleSuccessfullyChanged">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="markedSuccessful = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Role Successfully Changed
            </p>
            <p class="text-center">
              You have successfully changed this user's role.
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminRoles' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="markedSuccessful = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";

import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";

import Multiselect from "vue-multiselect";
import FormError from "@/components/FormError.vue";

const roleDetail = ref({});
const showPassword = ref(false);

const userTypes = ref([
  { name: "Super Admin", key: "super_admin" },
  { name: "Member", key: "member" },
]);

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const loading = ref(false);
const loading2 = ref(false);

const formErrors = ref({});

const router = useRouter();

const { id } = useRoute().params;

function formatWord(word) {
  return word?.replace(/_/g, " ");
}

const formattedWord = ref("");
function getRoleDetail() {
  loading.value = true;
  axios
    .get(`/admin/users/${id}`)
    .then((response) => {
      roleDetail.value = response.data.data;
      formattedWord.value = roleDetail.value?.user_type?.replace(/_/g, " ");
      loading.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}

function updateUserDetail() {
  loading2.value = true;
  axios
    .put(`/admin/users/${id}`, roleDetail.value)
    .then((response) => {
      showUpdateRole.value = false;
      roleSuccessfullyChanged.value = true;
      loading2.value = false;
    })
    .catch((error) => {
      formErrors.value = catchAxiosExceptions(error).formErrors;

      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

function removeUser() {
  axios
    .delete(`/admin/users/${id}`)
    .then((response) => {
      utils.showToast("Successful", "success");
      router.push({ name: "AdminRoles" });
    })
    .catch((error) => {
      formErrors.value = catchAxiosExceptions(error).formErrors;

      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user?.id == id) {
    router.push({ name: "AdminRoles" });
    utils.showToast(
      "Please update your details in settings, Thank you",
      "warning"
    );
  }
  getRoleDetail();
});

const showRemoveAdmin = ref(false);
const roleSuccessfullyChanged = ref(false);
const showUpdateRole = ref(false);

// function successfullySubmitted() {
//   showUpdateRole.value = false;
//   roleSuccessfullyChanged.value = true;
// }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
/* *{
    outline: 1px solid red;
} */

:deep(.multiselect__element) {
  background-color: transparent !important;
}

:deep(.multiselect__option--highlight) {
  background-color: blue !important;
  color: #fff !important;
}
.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 500 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.remove-btn {
  background-color: #ffeeee !important;
  font-weight: 500 !important;
  border: none;
  color: #d72509 !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
.pt-6 {
  padding-top: 6rem !important;
}
.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #f29036 !important;
  background-color: #fff9e2 !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.bg-delete {
  background-color: #fff4f4 !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}
</style>
