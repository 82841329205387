<template>
  <div>
    <UserLayout>
      <section class="bg-signIn d-flex">
        <div class="container">
          <div class="py-6">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-9">
                <div class="d-lg-flex justify-content-between align-items-end">
                  <div class="mt-3">
                    <div>
                      <router-link
                        :to="{ name: 'UserDashboard' }"
                        class="text-decoration-none text-body-secondary ms-2"
                      >
                        <img
                          src="../../assets/back.png"
                          alt=""
                          class="img-fluid me-2 mb-1"
                        />Back</router-link
                      >
                    </div>
                    <h2 class="header-text text-dark mb-0">
                      Testimony Details
                    </h2>
                  </div>

                  <button
                    class="updated btn shadow-sm"
                    @click.prevent="showMarkedAnswer = true"
                  >
                    Mark as Answered
                  </button>
                </div>

                <div class="card p-3 mt-4 shadow-sm b-color">
                  <div class="d-flex justify-content-center">
                    <MediaPlayer :media-url="mediaUrl" />
                  </div>
                  <form
                    action=""
                    class="border-1 rounded-2 p-3"
                    @submit.prevent="updateTestimony"
                  >
                    <div class="row">
                      <div class="col-12">
                        <div class="my-3">
                          <p class="form-label">Content</p>
                          <input
                            type="text"
                            placeholder=""
                            class="form-control form-bg p-3"
                            v-model="testimonyData.content"
                          />
                        </div>
                        <!-- <div class="my-3">
                          <p class="form-label">Prayer Point</p>
                          <input
                            type="text"
                            placeholder=""
                            class="form-control form-bg p-3"
                            v-model="testimonyData.conversation.messages[0].message"
                          />
                        </div> -->
                        <div class="col-lg-12 my-4">
                          <h6 class="form-label">Testimony File</h6>
                          <input
                            type="file"
                            ref="fileInput"
                            class="form-control p-3 bg-form"
                            @change="handleFileChange"
                          />
                        </div>

                        <div class="my-4">
                          <p>
                            <button
                              class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                              type="submit"
                            >
                              <span> Update</span>
                              <div>
                                <img
                                  src="../../assets/arrow-right.svg"
                                  alt=""
                                  width="40"
                                  height="40"
                                  class="img-fluid"
                                  v-if="!loading"
                                />
                                <div
                                  class="spinner-grow text-white"
                                  role="status"
                                  v-else-if="loading"
                                >
                                  <span class="visually-hidden"
                                    >Loading...</span
                                  >
                                </div>
                              </div>
                            </button>
                          </p>
                        </div>

                        <div class="mt-5">
                          <p class="form-label">Testimony Replies/Comments</p>
                          <div v-if="events.length == 0" class="mt-3">No Replies or Comments at the moment</div>
                          <VerticalTimeLine
                            :timelineEvents="events"
                            class="mt-4"
                          />
                        </div>
                      </div>
                    </div>
                  </form>

                  <form class="w-75 mx-auto">
                   
                    <p class="header-text2 mb-1">Comments</p>
                    <textarea
                      name=""
                      id=""
                      v-model="conversationDetail"
                      class="form-control"
                    ></textarea>
                    <p class="mt-4">
                      <button
                        class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                        @click.prevent="sendMessage"
                      >
                        <span>Add Comment</span>
                        <div>
                          <img
                            src="../../assets/arrow-right.svg"
                            alt=""
                            width="40"
                            height="40"
                            class="img-fluid"
                            v-if="!loading3"
                          />
                          <div
                            class="spinner-grow text-white"
                            role="status"
                            v-else-if="loading3"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </button>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal :show="showMarkedAnswer" width="650">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showMarkedAnswer = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/markAnswered.png"
                alt=""
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Mark Prayer as Answered
            </p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              Do you want to mark this prayer as answered
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click.prevent="checkAnswered"
              >
                Yes, This Prayer is Answered
              </button>
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click="showMarkedAnswer = false"
              >
                Close
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
      <Modal :show="showSubmittedSuccessfully" width="650">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="showSubmittedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/success.png"
                alt=""
                class="img-fluid"
                width="120"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">Prayer Answered</p>
            <p class="fw-light mt-1 text-center text-body-secondary">
              May God's blessing be always and forever!
            </p>
            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <button
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 ms-3"
              >
                Give Testimony
              </button>
              <button
                class="btn updated btn-lg text-capitalize font-weight-bold w-100 ms-3"
                @click="showSubmittedSuccessfully = false"
              >
                Back to Home
              </button>
            </p>
          </div>
        </template>
        <!-- <template #footer>
          <p>hello</p>
        </template> -->
      </Modal>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, watch } from "vue";

import { useRoute } from "vue-router";
import VerticalTimeLine from "@/components/VerticalTimeLine.vue";
import Modal from "@/components/Modal.vue";
import UserLayout from "@/components/Layouts/UserLayout.vue";
import MediaPlayer from "@/components/DisplayMedia.vue";

import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

const { id } = useRoute().params;

const testimonyData = ref({});

const showMarkedAnswer = ref(false);

const showConversationBox = ref(false);
const conversationId = ref(0);
const conversationDetail = ref("");
const loading3 = ref(false);

const loading = ref(false);
const showSubmittedSuccessfully = ref(false);

const events = ref([]);

watch(testimonyData, (newVal, oldVal) => {
  testimonyData.value = newVal;
});

const mediaUrl = ref("");
const mediaType = ref("");

// Function to detect media type
function detectMediaType(url) {
  const extension = url.split(".").pop().toLowerCase();
  if (["mp4", "webm", "avi", "mov"].includes(extension)) return "video";
  if (["mp3", "wav", "ogg"].includes(extension)) return "audio";
  if (["jpg", "jpeg", "png", "gif", "bmp"].includes(extension)) return "image";
  return "unknown";
}

function sendMessage() {
  loading3.value = true;
  axios
    .post(`/conversations/${conversationId.value}/messages`, {
      message: conversationDetail.value,
    })
    .then((response) => {
      utils.showToast("Successful", "success");
      getTestimonyInfo();
      loading3.value = false;
      conversationDetail.value = "";
      showConversationBox.value = false;
    })
    .catch((error) => {
      utils.showToast("Error sending message", "error");
      loading3.value = false;
    });
}

function getTestimonyInfo() {
  axios
    .get(`/testimonies/${id}`)
    .then((response) => {
      testimonyData.value = response.data.data;
      mediaUrl.value = response.data.data?.file_path;

      events.value = response.data.data?.conversation?.messages;
      conversationId.value = response.data.data?.conversation?.id;
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

function updateTestimony() {
  const payload = {
    ...testimonyData.value,
    _method: "PUT",
  };
  loading.value = true;
  axios
    .post(`/testimonies/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      utils.showToast("Success", "success");
      loading.value = false;
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading.value = false;
    });
}

const fileInput = ref(null);

function handleFileChange(event) {
  const file = event.target.files[0];
  if (file) {
    testimonyData.value.file_path = file;
  }
}

onMounted(() => {
  getTestimonyInfo();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */
.form-bg {
  background: rgba(245, 251, 252, 1) !important;
  color: #131313 !important;
}
.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}
.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.py-6 {
  padding: 6rem 0;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.p-card1 {
  background-color: rgba(248, 248, 248, 1) !important;
}

.p-card2 {
  background-color: rgba(237, 255, 227, 1);
}
</style>
