<template>
  <router-view/>
</template>

<script setup>
import { onMounted } from "vue"

import AOS from "aos"
import "aos/dist/aos.css"

onMounted(() => {
  AOS.init()
})
</script>

<style>

 @font-face {
    font-family: "Icomoon";
    src: url(../public/44393f83cfe6e236d297.ttf) format("truetype");
  }

 body,
  html {
    overflow-x: hidden !important;
  }

  /* scrollbar */
::-webkit-scrollbar {
  display: none !important;
}

  input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus,
select:active,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}


@font-face {
  font-family: "RedHats";
  src: local("RedHatDisplay-Black"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Black.ttf") format("truetype"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Black.ttf") format("truetype");
  font-weight:bolder;
  font-style: normal;
}
@font-face {
  font-family: "RedHats";
  src: local("RedHatDisplay-Black"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Bold.ttf") format("truetype"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Bold.ttf") format("truetype");
  font-weight:bold;
  font-style: normal;
}
@font-face {
  font-family: "RedHats";
  src: local("RedHatDisplay-ExtraBold"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-ExtraBold.ttf") format("truetype"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-ExtraBold.ttf") format("truetype");
  font-weight:900;
  font-style: normal;
}
@font-face {
  font-family: "RedHats";
  src: local("RedHatDisplay-SemiBold"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-SemiBold.ttf") format("truetype"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "RedHats";
  src: local("RedHatDisplay-Medium"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Medium.ttf") format("truetype"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "RedHats";
  src: local("RedHatDisplay-Regular"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Regular.ttf") format("truetype"),
    url("/public/Red_Hat_Display/static/RedHatDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
*{
  font-family: "RedHats", sans-serif;
}

.text-primary{
  color:  rgba(10, 64, 194, 1) !important;
}

.text-black{
  color: rgba(6, 17, 5, 1) !important;
}

.bg-transparent{
  background-color: transparent !important;
}

.bg-light-2{
  background-color: rgba(245, 255, 240, 1) !important;
}

.bg-semi-transparent{
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.bg-primary2{
  background-color:rgba(213, 242, 255, 1) !important;
}
.bg-primary3{
  background-color: rgba(255, 241, 213, 1) !important;
}

.bg-about{
  background-color:  rgba(47, 84, 214, 1) !important;
}

.bg-dashboard-nav{
  background-color:rgba(10, 64, 194, 1) !important;
}



.border-semi-light{
  border: 2px dotted rgba(255, 255, 255, 0.7) !important;
}

.btn-primary {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  padding: 1rem 1.5rem !important;
  border-radius: 9px !important;
  border: none !important;
  position: relative !important;
  overflow: hidden !important;
}

.btn-primary:hover {
  background-color: rgba(10, 64, 194, 0.8) !important;
  box-shadow: 0 4px 8px rgba(10, 64, 194, 0.3) !important;
  transform: translateY(-4px) !important;

}

.btn-ligh{
  background-color:#fff !important;
  color:#0B40C2 !important;
  font-weight:600 !important;
  border: none !important;
  font-size:1rem !important;
  padding: 1rem 1.5rem !important;
  border-radius: 9px !important;
}

.btn-ligh:hover {
  background-color: #f0e6ff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-4px);
}

</style>
