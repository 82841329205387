<template>
  <div>
    <AdminLayout Title="Prayer Meetings" :isRequired="false">
      <div class="container pt-6">
        <router-link
          :to="{ name: 'AdminPrayerMeetings' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 d-flex justify-content-center">
          <div class="col-12 col-lg-7">
            <div
              class="p-4 position-relative home3 rounded-bottom-0 rounded-top-3"
              style="cursor: pointer"
            >
              <!-- <img
                src="../../assets/church2.png"
                class="position-absolute"
                style="right: 0.8rem; top: 0.6rem"
                alt=""
              /> -->
              <div class="text-white py-3">
                <h4 class="fw-semibold text-capitalize">
                  Add New Prayer Meeting
                </h4>
              </div>
            </div>
            <div class="bg-white rounded-bottom-1 p-3 b-color">
              <form class="mt-3 border-0">
                <!-- <div class="mb-3">
                  <p class="form-label fw-medium">Background Image</p>
                  <input
                    type="text"
                    class="form-control p-3"
                    placeholder="En"
                  />
                </div> -->
                <div class="mb-3">
                  <p class="form-label fw-medium">Title</p>
                  <input
                    type="text"
                    class="form-control p-3"
                    placeholder="Enter Meeting Title"
                    v-model="meetingPayload.title"
                  />
                  <FormError :errorMessage="formErrors?.title" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Meeting Location</p>
                  <input
                    type="text"
                    class="form-control p-3"
                    placeholder="Enter Meeting Location"
                    v-model="meetingPayload.meeting_location"
                  />
                  <FormError :errorMessage="formErrors?.meeting_location" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Type of meeting</p>
                  <multiselect
                    v-model="meetingPayload.type"
                    :options="meetingTypes"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :show-no-results="false"
                  >
                    <template #noResult>
                      <span
                        >Oops! No Days found. Consider changing your search
                      </span>
                    </template>
                  </multiselect>
                  <FormError :errorMessage="formErrors?.type" />
                </div>

                <div class="mb-3">
                  <p class="form-label fw-medium">Recurring Start Time</p>
                  <input
                    type="time"
                    class="form-control p-3"
                    v-model="meetingPayload.recurring_start_time"
                  />
                  <FormError :errorMessage="formErrors?.recurring_start_time" />
                </div>
                <div class="mb-3">
                  <p class="form-label fw-medium">Days</p>
                  <multiselect
                    v-model="days"
                    :options="daysOfWeek"
                    label="day"
                    track-by="id"
                    value-key="id"
                    :multiple="true"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :show-labels="false"
                    :show-no-results="false"
                  >
                    <template #noResult>
                      <span
                        >Oops! No Days found. Consider changing your search
                      </span>
                    </template>
                  </multiselect>
                  <FormError :errorMessage="formErrors.status" />
                </div>

                <!-- <h5>Zoom Details</h5>
                <div class="mb-3">
                  <p class="form-label fw-medium">Zoom Meeting Type</p>

                  <multiselect
                    v-model="zoomSelectedSchedule"
                    :options="zoomSchedule"
                    label="name"
                    track-by="num"
                    value-key="num"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :show-no-results="false"
                  >
                    <template #noResult>
                      <span
                        >Oops! No Schedule found. Consider changing your search
                      </span>
                    </template>
                  </multiselect>
                  
                </div> -->

                 <!-- <div class="mb-3">
                  <p class="form-label fw-medium">Meeting Duration</p>
                  <input
                    type="number"
                    class="form-control p-3"
                    placeholder="Enter number of minutes"
                    v-model="zoomDuration"
                  />
                </div> -->
                 <div class="mb-3">
                  <p class="form-label fw-medium">Fixed Schedule</p>
                  <input
                    type="datetime-local"
                    class="form-control p-3"
                    v-model="fixedSchedule"
                  />
                </div>



                <div class="mb-3">
                  <p class="form-label fw-medium">Meeting Description</p>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-model="meetingPayload.description"
                    placeholder="Enter meeting description here"
                  ></textarea>
                  <FormError :errorMessage="formErrors?.description" />
                </div>

                <p class="mt-4">
                  <button
                    class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                    @click.prevent="postMeeting"
                  >
                    <span>Add New Meeting</span>
                    <div>
                      <img
                        src="../../assets/arrow-right.svg"
                        alt=""
                        width="40"
                        height="40"
                        class="img-fluid"
                        v-if="!loading"
                      />
                      <div
                        class="spinner-grow text-white"
                        role="status"
                        v-else-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal :show="postedSuccessfully">
        <template #content>
          <div
            class="d-flex justify-content-center flex-column position-relative"
          >
            <img
              src="../../assets/close-circle.png"
              alt=""
              class="img-fluid position-absolute"
              style="top: 0; right: 0; cursor: pointer"
              @click.prevent="postedSuccessfully = false"
            />
            <div class="d-flex justify-content-center">
              <img
                src="../../assets/admin-check-success.svg"
                class="img-fluid"
                width="150"
              />
            </div>
            <p class="text-center mt-3 header-text2 mb-0">
              Prayer Meeting successfully Added
            </p>

            <p
              class="text-center d-block d-md-flex justify-content-center mt-3"
            >
              <router-link
                :to="{ name: 'AdminPrayerMeetings' }"
                class="updated btn-lg text-capitalize font-weight-bold ms-2 ms-md-4 text-decoration-none"
                @click.prevent="postedSuccessfully = false"
              >
                Back to Home
              </router-link>
            </p>
          </div>
        </template>
        <!-- <template #footer>
        <p>hello</p>
      </template> -->
      </Modal>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import Modal from "@/components/Modal.vue";
import FormError from "@/components/FormError.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";
import Multiselect from "vue-multiselect";

const meetingPayload = ref({
  title: null,
  meeting_location: null,
  description: null,
  type: null,
  recurring_start_time: null,
  fixed_schedule: null,
  days: null,
  // zoom: {
  //   type: null,
  //   duration: null,
  //   start_time: null,
  // },
});

const meetingTypes = ref(["recurring", "one_time"]);

const zoomSchedule = ref([
  { name: "Instant Meeting", num: 1 },
  { name: "Scheduled Meeting", num: 2 },
]);

function formatDateTime(input) {
  const date = new Date(input);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const formErrors = ref({});

watch(zoomSchedule, (newValue, oldValue) => {
  zoomSelectedSchedule.value = newValue;
});

const zoomSelectedSchedule = ref({});

const days = ref([]);

const zoomDuration = ref(null)
const fixedSchedule = ref(null)

const selectedDays = ref([]);

watch(days, (newValue) => {
  selectedDays.value = newValue.map((day) => day.id);
});

watch(selectedDays, (newValue) => {
  selectedDays.value = newValue;
});
const daysOfWeek = ref([
  { day: "Monday", id: 1 },
  { day: "Tuesday", id: 2 },
  { day: "Wednesday", id: 3 },
  { day: "Thursday", id: 4 },
  { day: "Friday", id: 5 },
  { day: "Saturday", id: 6 },
  { day: "Sunday", id: 7 },
]);

const loading = ref(false);
const postedSuccessfully = ref(false);

function postMeeting() {
  formErrors.value = {};
  loading.value = true;

  

  meetingPayload.value.days = selectedDays.value;
  // meetingPayload.value.zoom.type = zoomSelectedSchedule.value?.num;
  // meetingPayload.value.zoom.duration = zoomDuration.value;
  meetingPayload.value.fixed_schedule = formatDateTime(fixedSchedule.value);
  axios
    .post(`/admin/prayer-meetings`, meetingPayload.value)
    .then((response) => {
      loading.value = false;
      postedSuccessfully.value = true;
    })
    .catch((error) => {
      loading.value = false;
      formErrors.value = error.response?.data?.errors;
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
:deep(.multiselect__tags),
:deep(.multiselect__input),
:deep(.multiselect__single) {
  background-color: #f5fbfc !important;
}
input,
textarea {
  background-color: #f5fbfc !important;
}
.pt-6 {
  padding-top: 6rem !important;
}

:deep(.multiselect__element) {
  background-color: transparent !important;
}

:deep(.multiselect__option--highlight),
:deep(.multiselect__tag) {
  background-color: blue !important;
  color: #fff !important;
}
.b-color {
  border: 1px solid #ddedff !important;
}

.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.home3 {
  background-color: rgba(224, 58, 111, 1) !important;
  background-image: url("../../assets/sunset-background.png") !important;
  background-blend-mode: multiply !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.5rem;
}
</style>