<!-- components/Tooltip.vue -->
<template>
  <div 
    :data-bs-toggle="'tooltip'"
    :title="text"
    :data-bs-placement="placement"
    ref="tooltipRef"
  >
    <slot></slot>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { Tooltip } from 'bootstrap'

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom', 'left', 'right'].includes(value)
    }
  },
  setup() {
    const tooltipRef = ref(null)
    let tooltip = null

    onMounted(() => {
      // Initialize tooltip on the specific element
      tooltip = new Tooltip(tooltipRef.value)
    })

    onBeforeUnmount(() => {
      if (tooltip) {
        tooltip.dispose()
      }
    })

    return {
      tooltipRef
    }
  }
}
</script>