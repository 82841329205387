<template>
  <UserLayout>
    <div class="container">
      <div class="pt-6 pb-4">
        <router-link
          :to="{ name: 'UserCounselling' }"
          class="text-decoration-none text-body-secondary ms-2"
          v-if="store?.user"
        >
          <img
            src="@/assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <p class="text-end">
          <button
            class="btn btn-primary"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            v-if="inChannel"
          >
            Chat <i class="bi bi-chat-left-text ms-1 mt-3"></i>
          </button>
        </p>

        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">Messages</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ChatRoom
              :channelName="channelName"
              :sender="usernames[localUserId]"
            />
          </div>
        </div>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasRight1"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header">
            <h5 class="" id="offcanvasRightLabel">Attendees</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-bod">
            <ul class="list-unstyled ms-3">
              <li style="font-size: 1.2rem" class="d-flex align-items-center">
                <span class="me-3 text-capitalize"
                  >{{
                    usernames[localUserId] || store?.user?.first_name
                  }}
                  (You)</span
                >
              </li>
              <li
                v-if="remoteUsers.length == 0"
                style="font-size: 1.2rem"
                class="mt-2"
              >
                No Attendee
              </li>
              <li
                v-for="(user, index) in remoteUsers"
                :key="index"
                class="d-flex justify-content-between align-items-center"
                style="font-size: 1.2rem"
              >
                <div class="d-flex align-items-center">
                  <span class="me-3 text-capitalize">{{
                    usernames[user.uid] || `user ${user.uid}`
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center" v-if="noUserName">
        <div class="col-10 col-lg-5">
          <form class="mt-3 border-0 bg-white p-3">
            <div class="mb-3">
              <p class="form-label fw-medium">User Name</p>
              <input
                type="text"
                class="form-control p-3"
                placeholder="Enter your name here"
                v-model="userName"
              />
            </div>

            <p class="mt-4">
              <button
                :disabled="userName == ''"
                class="btn signInBtn btn-lg text-capitalize font-weight-bold w-100 d-flex justify-content-between align-items-center"
                @click.prevent="setUserName"
              >
                <span>Proceed</span>
                <div>
                  <img
                    src="@/assets/arrow-right.svg"
                    alt=""
                    width="40"
                    height="40"
                    class="img-fluid"
                  />
                </div>
              </button>
            </p>
          </form>
        </div>
      </div>

      <div v-else>
        <div class="bg-dark">
          <div
            style="
              min-height: 50vh;
              background-color: #131313;
              border-radius: 15px;
            "
            v-if="!inChannel"
          ></div>

          <div class="room" style="min-height: 60vh" v-else>
            <div class="user-lis">
              <div class="row g-2">
                <div
                  class="col-12 col-lg-3 position-relative user-local-container"
                  v-show="inChannel"
                >
                  <div class="user-local rounded-3">
                    <div
                      id="local-video"
                      style="
                        width: 100%;
                        height: 100%;
                        position: relative;

                        background-color: #131313;
                      "
                      :style="{
                        border: `3px solid ${generateRandomColor()}`,
                        borderRadius: '10px',
                      }"
                    >
                      <div
                        class="position-absolute"
                        style="left: 40%; top: 40%"
                        v-if="videoMuted"
                      >
                        <div
                          style="
                            border-radius: 50%;
                            background-color: rgb(61, 114, 239);
                            padding: 1.3rem;
                            font-weight: 600;
                          "
                        >
                          {{
                            store?.user?.first_name
                              ? store?.user?.first_name.charAt(0)
                              : "You"
                          }}
                        </div>
                      </div>
                      <samp
                        class="user-name position-absolute"
                        style="left: 0; z-index: 999"
                      ></samp>
                    </div>
                  </div>
                </div>
                <div
                  class="col-4 col-md-2 col-lg-3 user-local-container"
                  v-if="remoteUsers.length == 0"
                >
                  <div
                    class="no-users d-flex justify-content-center align-items-center flex-column"
                  >
                    <p>
                      <img
                        src="@/assets/admin-crowd.svg"
                        alt="People"
                        class="img-fluid"
                        width="100"
                      />
                    </p>
                    <p class="text-white text-center" style="font-size: 1.3rem">
                      No Participant In the Meeting
                    </p>
                  </div>
                </div>

                <div
                  class="col-4 col-lg-3 user-local-container"
                  v-for="user in visibleUsers"
                  :key="user.uid"
                >
                  <div
                    class="user-local position-relative remote"
                    :style="{
                      border: `3px solid ${generateRandomColor()}`,
                      borderRadius: '10px',
                      backgroundColor: 'black',
                    }"
                  >
                    <div
                      class="position-absolute"
                      style="left: 40%; top: 40%"
                      v-if="user?.videoMuted"
                    >
                      <div
                        style="
                          border-radius: 50%;
                          background-color: rgb(61, 114, 239);
                          padding: 1.3rem;
                          font-weight: 600;
                        "
                      >
                        {{ (usernames[user.uid] || "Guest").charAt(0) }}
                      </div>
                    </div>
                    <samp
                      class="user-name position-absolute"
                      style="background-color: #131313"
                      >{{ usernames[user.uid] || "Guest" }}</samp
                    >
                    <div :id="`remoteVideo-${user.uid}`"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="controls d-flex justify-content-between mt-3 p-1">
            <div class="d-flex align-items-center">
              <button
                @click.prevent="initializeClient"
                class="m-2 signInBtn d-flex align-items-center"
                v-if="!inChannel"
              >
                <span class="">Join Meeting</span>
                <div class="spinner-grow ms-3" role="status" v-if="loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <button
                @click.prevent="toggleLocalAudio"
                class="toggleBtn rounded-circle shadow-lg"
                v-if="inChannel"
              >
                <i
                  :class="
                    audioMuted
                      ? 'bi bi-mic-mute text-danger'
                      : 'bi bi-mic text-success'
                  "
                ></i>
              </button>
              <button
                @click.prevent="toggleLocalVideo"
                class="toggleBtn rounded-circle shadow-lg"
                v-if="inChannel"
              >
                <i
                  :class="
                    videoMuted
                      ? 'bi bi-camera-video-off text-danger'
                      : 'bi bi-camera-video text-success'
                  "
                ></i>
              </button>

              <div v-if="inChannel">
                <button
                  class="fs-3 mx-2 rounded-circle border-0"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight1"
                  aria-controls="offcanvasRight1"
                >
                  <img
                    src="@/assets/groupPeople.svg"
                    alt=""
                    class="img-fluid"
                  />
                </button>
              </div>
            </div>

            <div class="d-flex align-self-center">
              <button @click.prevent="leaveChannel" class="signOutBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 8q2.95 0 5.813 1.188T22.9 12.75q.3.3.3.7t-.3.7l-2.3 2.25q-.275.275-.638.3t-.662-.2l-2.9-2.2q-.2-.15-.3-.35t-.1-.45v-2.85q-.95-.3-1.95-.475T12 10t-2.05.175T8 10.65v2.85q0 .25-.1.45t-.3.35l-2.9 2.2q-.3.225-.663.2t-.637-.3l-2.3-2.25q-.3-.3-.3-.7t.3-.7q2.2-2.375 5.075-3.562T12 8"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </UserLayout>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  collection,
  doc,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "@/config/firebase";
import ChatRoom from "@/components/ChatRoom.vue";
import {
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-sdk-ng";
import axios from "@/Plugins/axios";
import UserLayout from "@/components/Layouts/UserLayout.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import { useAuthStore } from "@/stores/auth";

import utils from "@/Plugins/utils";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const route = useRoute();

const messages = ref([]);

const store = useAuthStore();

const userRole = store?.user?.user_type;

const channelName = route.query?.channel;

const raisedHands = ref([]);

const meetingUsername = ref("");
const userName = ref("");
const noUserName = ref(false);

const usernames = reactive({});

const remoteAudioMuted = ref(true);
const remoteVideoMuted = ref(false);
// Reactive references
let remoteUsers = reactive([]);

const visibleUsers = computed(() => remoteUsers.slice(0, 1));
const extraUserCount = computed(() =>
  remoteUsers.length > 9 ? remoteUsers.length - 9 : 0
);
const audioMuted = ref(false);
const videoMuted = ref(false);
const router = useRouter();

// State for showing limited or all users
const showAll = ref(false);
const loading = ref(false);

watch(remoteUsers, (newUsers) => {
  remoteUsers = newUsers;
});
watch(messages, (newVal) => {
  messages.value = newVal;
});

const inChannel = ref(false);
watch(inChannel, (newVal) => {
  inChannel.value = newVal;
});

const meetingRoomRef = ref("");
const messagesCollection = ref("");
const messagesQuery = ref("");
const recentReaction = ref("");

const recentPerson = ref("");

const CHANNEL = ref("");
const TOKEN = ref(null);
const USER_ID = ref("");

const localUserId = ref(null);
const audioIds = ref([]);
const videoIds = ref([]);

// Initialize Agora client
const client = createClient({ mode: "rtc", codec: "vp8" });
const networkQuality = ref({ uplink: 0, downlink: 0 });
// Local tracks
const localTracks = {
  audioTrack: null,
  videoTrack: null,
};

const toggleLocalVideo = async () => {
  if (localTracks.videoTrack) {
    videoMuted.value = !videoMuted.value;
    console.log(videoMuted.value ? "Video muted" : "Video unmuted");

    if (videoMuted.value) {
      await client.unpublish([localTracks.videoTrack]);

      localTracks.videoTrack.stop();
    } else {
      await localTracks.videoTrack.setEnabled(true);
      await client.publish([localTracks.videoTrack]);

      const localContainer = document.getElementById("local-video");
      localTracks.videoTrack.play(localContainer);
    }
    console.log(videoMuted.value ? "video disabled" : "video enabled");
  }
};

const toggleLocalAudio = async () => {
  // alert(localTracks.audioTrack.isEnabled)
  if (localTracks.audioTrack) {
    audioMuted.value = !audioMuted.value;
    console.log(audioMuted.value ? "Audio muted" : "Audio unmuted");

    if (audioMuted.value) {
      await client.unpublish([localTracks.audioTrack]);
      //  await localTracks.audioTrack.setEnabled(false);
      await localTracks.audioTrack.setMuted(true);
      localTracks.audioTrack.stop();
    } else {
      await localTracks.audioTrack.setEnabled(true);
      await localTracks.audioTrack.setMuted(false);

      await client.publish([localTracks.audioTrack]);
      localTracks.audioTrack.play();
    }
    console.log(audioMuted.value ? "audio disabled" : "audio enabled");
  }
};

// Leave the channel
const leaveChannel = async () => {
  inChannel.value = false;
  if (localTracks.audioTrack) localTracks.audioTrack.stop();
  if (localTracks.videoTrack) localTracks.videoTrack.stop();
  if (localTracks.audioTrack) localTracks.audioTrack.close();
  if (localTracks.videoTrack) localTracks.videoTrack.close();

  await client.leave();
  client.off("network-quality");
  client.on("user-unpublished", handleUserUnpublished);
  client.on("user-left", handleUserLeft);
  console.log("Left the channel");

  if (userRole == "super_admin") {
    router.push({ name: "AdminDashboard" });
  }
  if (userRole == "member") {
    router.push({ name: "UserDashboard" });
  }
  if (!store?.user) {
    router.push({ name: "home" });
  }
};

async function generateAgoraToken() {
  let idUser = JSON.parse(localStorage.getItem("user"));
  try {
    const response = await axios.post(`/agora-token`, {
      channel_name: channelName,
      token_expiry: 360000,
      privilege_expiry: 360000,
      uid: idUser?.id ? idUser?.id : 0,
    });
    return response.data?.data?.token;
  } catch (error) {
    console.error("Error generating Agora token:", error.message);
  }
}

// Initialize Agora client and join the channel
const initializeClient = async () => {
  loading.value = true;
  let idUser = JSON.parse(localStorage.getItem("user"));
  USER_ID.value = idUser?.id ? idUser?.id : 0;
  try {
    TOKEN.value = await generateAgoraToken();
    // Join the channel
    let uid = await client.join(
      process.env.VUE_APP_AGORA_APP_ID,
      CHANNEL.value,
      TOKEN.value,
      USER_ID.value
    );
    localUserId.value = uid;

    inChannel.value = true;
    console.log("Joined channel successfully");
    loading.value = false;

    if (!store?.user) {
      await saveUsername(localUserId.value, userName.value);
    }
    // userName.value = ""
    // localStorage.removeItem("guest")

    client.on("user-joined", handleUserJoined);
    client.on("user-published", handleUserPublished);
    client.on("user-left", handleUserLeft);
    // Create local audio and video tracks
    [localTracks.audioTrack, localTracks.videoTrack] =
      await createMicrophoneAndCameraTracks(
        {
          enabled: false, // Start with audio muted
          enabled: false, // Start with audio muted
          AEC: true, // Enable echo cancellation
          AGC: true, // Enable auto gain control
          ANS: true, // Enable noise suppression
        },
        {
          enabled: false, // Start with video muted
        }
      );

    // const localContainer = document.getElementById("local-video");
    document.querySelector(".user-name").textContent = `You`;

    // Set up local video with muted state
    if (localTracks.videoTrack) {
      await localTracks.videoTrack.setEnabled(false);
      // localTracks.videoTrack.play(localContainer);
      videoMuted.value = true;
    }

    // Mute local audio
    if (localTracks.audioTrack) {
      await localTracks.audioTrack.setEnabled(false);
      audioMuted.value = true;
    }

    // Publish local tracks
    await client.publish([localTracks.audioTrack, localTracks.videoTrack]);

    console.log("Published local tracks successfully");
  } catch (error) {
    loading.value = false;
    console.error("Error initializing Agora client:", error);
    // utils.showToast(error, "error");
  }
};

function generateRandomColor() {
  const randomNum = Math.floor(Math.random() * 16777215);
  return `#${randomNum.toString(16).padStart(6, "0")}`;
}

async function saveUsername(uid, username) {
  const docRef = doc(db, "meeting_room", channelName, "local_guests", "guests");

  try {
    // Use updateDoc if the document exists, otherwise use setDoc
    await updateDoc(docRef, {
      [uid]: username, // Add or update the UID field with the username
    });
    userName.value = "";
    if (localStorage.getItem("guest")) {
      localStorage.removeItem("guest");
    }
  } catch (error) {
    if (error) {
      // Document doesn't exist, create it
      await setDoc(docRef, {
        [uid]: username,
      });
      userName.value = "";
      if (localStorage.getItem("guest")) {
        localStorage.removeItem("guest");
      }
    } else {
      console.error("Error saving username:", error);
    }
  }
}

// Will be called in the template section to get a name that matches the uid
function listenToUsernames() {
  const docRef = doc(db, "meeting_room", channelName, "local_guests", "guests");

  // Set up Firestore onSnapshot listener
  onSnapshot(
    docRef,
    (docSnap) => {
      if (docSnap.exists()) {
        Object.assign(usernames, docSnap.data()); // Update reactive `usernames` object
      } else {
        console.warn("Document not found");
      }
    },
    (error) => {
      console.error("Error listening to usernames:", error);
    }
  );

  // Return the unsubscribe function to stop listening when needed
}

// Handle when a remote user publishes their media
async function handleUserPublished(user, mediaType) {
  await client.subscribe(user, mediaType); // Subscribe to the user's media

  if (mediaType === "video" && user.videoTrack) {
    // Create and display the remote video container
    // const videoContainer = document.createElement("div");
    // videoContainer.id = `remoteVideo-${user.uid}`;
    // videoContainer.classList.add("user");

    meetingUsername.value = "";

    await nextTick();
    const remoteContainer = document.getElementById(`remoteVideo-${user.uid}`);
    remoteContainer.style.width = "100%";
    remoteContainer.style.height = "100%";
    remoteContainer.style.backgroundColor = "black";

    if (remoteContainer) {
      user.videoTrack.play(remoteContainer);
    } else {
      console.error("Remote container not found for user:", user.uid);
    }
  } else if (mediaType === "audio" && user.audioTrack) {
    user.audioTrack.play();
  }
}

// Function to handle user-unpublished event
const handleUserUnpublished = (user, mediaType) => {
  console.log(`User ${user.uid} unpublished ${mediaType}`);

  if (mediaType === "video") {
    const targetUser = remoteUsers.find((u) => u.uid === user.uid);
    if (targetUser) {
      targetUser.videoMuted = true; // Mark as muted
    }

    const remoteContainer = document.getElementById(`remoteVideo-${user.uid}`);
    remoteContainer.classList.remove("user");
    remoteContainer.removeChild("samp");
    if (remoteContainer) {
      remoteContainer.parentElement?.removeChild(remoteContainer);
      remoteUsers = remoteUsers.filter((u) => u.uid !== user.uid);
      remoteContainer.style.backgroundColor = "";
      remoteContainer.style.width = "0";
      remoteContainer.style.height = "0";
    }
  }

  if (mediaType === "audio") {
    user.audioTrack.stop();
  }
};

// Function to handle user-joined event
const handleUserJoined = async (user) => {
  toast(`${usernames[user?.uid] || "A user"} has joined the meeting`, {
    autoClose: 2000,
    position: "top-center",
    theme: "dark",
    closeButton: false,
  });

  if (user) {
    try {
      const userDetails = await axios.get(`/view-user/${user.uid}`);
      meetingUsername.value = `${userDetails.data.data?.first_name} ${userDetails.data.data?.last_name}`;
      await saveUsername(user.uid, meetingUsername.value);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        meetingUsername.value = usernames[user.uid] || "Guest";
        console.log("yes user not found");
      } else {
        console.error("Error fetching user details:", error);
      }
    }
  }
  let userAlreadyAdded = remoteUsers.find((u) => u.uid === user.uid);

  if (!userAlreadyAdded) {
    // Push the user to the remoteUsers array
    remoteUsers.push({
      uid: user.uid,
      name: meetingUsername.value,
      videoMuted: false,
      videoTrack: user.videoTrack || null,
      audioTrack: user.audioTrack || null,
    });
  }
  // const videoContainer = document.createElement("div");
  // videoContainer.id = `remoteVideo-${user.uid}`;
  // videoContainer.classList.add("user");
};

// Function to handle user-left event
const handleUserLeft = (user) => {
  toast(`${usernames[user?.uid] || "A user"} has left the meeting`, {
    autoClose: 2000,
    position: "top-center",
    theme: "dark",
    closeButton: false,
  });

  const index = remoteUsers.findIndex((u) => u.uid === user.uid);
  if (index !== -1) {
    // Remove the user from the `remoteUsers` array
    remoteUsers.splice(index, 1);
  }
  // Remove user from remoteUsers array and clean up their video
  const remoteContainer = document.getElementById(`remoteVideo-${user.uid}`);
  remoteContainer.classList.remove("user");
  remoteContainer.removeChild("samp");
  if (remoteContainer) {
    remoteContainer.style.backgroundColor = "";
    remoteContainer.style.width = "0";
    remoteContainer.style.height = "0";
    // Optionally, clean up the DOM element (only if you're not using Vue's reactivity)
    remoteContainer.parentElement?.removeChild(remoteContainer);
  }
};

// Cleanup function when component is unmounted
const cleanup = async () => {
  // Stop and close local tracks
  if (localTracks.videoTrack) {
    localTracks.videoTrack.stop();
    localTracks.videoTrack.close();
  }
  if (localTracks.audioTrack) {
    localTracks.audioTrack.stop();
    localTracks.audioTrack.close();
  }

  // Leave the channel
  await client.leave();
  console.log("Left the channel");
};

function setUserName() {
  // meetingUsername.value = userName.value;
  localStorage.setItem("guest", userName.value);
  noUserName.value = false;
}

// Lifecycle hooks
onMounted(() => {
  if (store?.user?.first_name == undefined || store?.user?.first_name == "") {
    noUserName.value = true;
  }
  client.on("user-published", handleUserPublished);
  client.on("user-unpublished", handleUserUnpublished);
  client.on("user-joined", handleUserJoined);
  client.on("user-left", handleUserLeft);

  listenToUsernames();

  const urlParams = new URLSearchParams(window.location.search);
  CHANNEL.value = urlParams.get("channel") || channelName;
});

onBeforeUnmount(() => {
  cleanup();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.dropdown-toggle::after {
  display: none !important;
}

.btn-primary {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  padding: 0.6rem 1rem;
  border-radius: 9px;
  border: none;
  position: relative;
  overflow: hidden;
}

.btn-primary:hover {
  background-color: rgba(10, 64, 194, 0.8) !important;
  box-shadow: 0 4px 8px rgba(10, 64, 194, 0.3) !important;
  transform: translateY(-4px);
}

.user-list {
  padding: 1.4rem;
  /* grid-gap: 1.25rem;
  gap: 1.25rem;
  display: flex;
  flex-wrap: nowrap; 
  flex-direction: row;  */
}

.user {
  border-style: solid;
  border-radius: 6px;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 50vw;
  height: 50vh;
}

.user-placeholder {
  border-radius: 50%; /* Make it circular */
  overflow: hidden; /* Ensure images don't overflow */
}

.user-local-container {
  width: 36vw;
  height: 40vh;
}
.user-local {
  border-style: solid;
  border-radius: 6px;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  /* width: 25vw; */
  /* height: 40vh; */
  width: 100%;
  height: 100%;
}

.extra-users {
  font-size: 1.2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
.user-name {
  --un-text-opacity: 1;
  color: rgba(255, 255, 255, var(--un-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --un-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--un-bg-opacity));
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
}

.no-users {
  width: 100%;
  height: 100%;
  background-color: #131313;
  border-radius: 6px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.room {
  max-width: 80vw;
  width: 75vw;
  margin: 0 auto;
  padding: 0.3rem;
}

@media only screen and (max-width: 596px) {
  .room {
    max-width: 95vw;
    width: 95vw;
    margin: 0 auto;
  }

  .user-local-container {
    width: 47vw;
    height: 40vh;
  }
  .user-local {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .user-list {
    padding: 1.4rem;
    /* grid-gap: 1.25rem;
  gap: 1.25rem;
  display: flex;
  flex-wrap: nowrap; 
  flex-direction: row;  */
  }
}

@media (max-width: 768px) {
  .user-local {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
  .user-local-container {
    width: 47vw;
    height: 40vh;
  }
  .user-list {
    padding: 1.4rem;
  }
}

@media only screen and (min-width: 568px) and (max-width: 821px) {
  .room {
    max-width: 95vw;
    width: 95vw;
    margin: 0 auto;
  }
  .user-list {
    flex-wrap: wrap; /* Ensures items wrap if needed */
    flex-direction: column-reverse; /* Stack items vertically */
  }

  .user-local-container {
    width: 40vw;
    height: 40vh;
  }

  .user-local {
    border-style: solid;
    border-radius: 6px;
    --un-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--un-border-opacity));
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}

.remote-videos {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  padding: 0 0.3rem 0 0.3rem;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.toggleBtn {
  margin: 5px;
  font-size: 1.4rem;
  padding: 0.4rem 0.7rem;
  border: 1px solid grey;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.5rem 1.5rem;
  border-radius: 9px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 0.7rem 1.5rem;
  border-radius: 9px;
}
</style>
