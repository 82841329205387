<template>
  <div>
    <UserLayout>

      <section class="bg-signIn d-flex">
        <div class="container">
          <div class="py-6">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-lg-11">
                <div class="mt-3">
                  <div>
                    <router-link
                      :to="{ name: 'UserDashboardPrayerRequest' }"
                      class="text-decoration-none text-body-secondary ms-2 "
                    >
                      <img
                        src="../../assets/back.png"
                        alt=""
                        class="img-fluid me-2 mb-1"
                      />Back</router-link
                    >
                  </div>
                  <h2 class="header-text text-dark mb-0">Active Prayers</h2>
                </div>
  
                  <div class="card b-color p-3 mt-3" v-if="activePrayerRequestData.length <= 0">
                      <h5>No Active Prayers</h5>
                    </div>
                <div class="card p-3 mt-4 shadow-sm b-color" v-else>
                  <div class="card p-card2 b-color p-3 mt-3" v-for="(prayer, index) in activePrayerRequestData" :key="index">
                    <div class="d-flex justify-content-between">
                      <h5>{{ prayer.subject }}</h5>
                      <p class="text-body-secondary">3 days Ago</p>
                    </div>
                    <p class="text-body-secondary mt-0" v-for="conversation in prayer.conversation?.messages" :key="conversation">
                     {{ conversation?.message }}
                    </p>
                  </div>
                 
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";



import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

import UserLayout from "@/components/Layouts/UserLayout.vue"

const prayerRequestData = ref([])
const activePrayerRequestData = ref([])

function getAllPrayerRequest(){
  axios
  .get(`/prayer-points`)
  .then(response => {
    prayerRequestData.value = response.data.data;

    activePrayerRequestData.value = prayerRequestData.value.filter((item) => item.is_answered == false)
   
  })
  .catch(error =>{
     catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
  })
}

onMounted(() =>{
  getAllPrayerRequest();
})
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.header-text {
  font-weight: 900;
  font-size: 2.3rem;
}
.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}

.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}
.bg-signIn {
  background-image: url("../../assets/bg-signIn.png");
  background-position: center;
  min-height: 100vh;
}

.py-6 {
  padding: 6rem 0;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.p-card1 {
  background-color: rgba(248, 248, 248, 1) !important;
}

.p-card2 {
  background-color: rgba(237, 255, 227, 1);
}
</style>
