import { createRouter, createWebHistory } from 'vue-router'
import Birthday from '../views/accounts/Birthday.vue'
import CreateNewPassword from '../views/accounts/CreateNewPassword.vue'
import ForgotPassword from '../views/accounts/ForgotPassword.vue'
import UserLogin from '../views/accounts/UserLogin.vue'
// import AdminLogin from '../views/accounts/AdminLogin.vue'
import Otp from '../views/accounts/Otp.vue'
import OtpVerified from '../views/accounts/OtpVerified.vue'
import UserProfile from '../views/accounts/UserProfile.vue'
import UserSignUp from '../views/accounts/UserSignUp.vue'
// import AdminSignUp from '../views/accounts/AdminSignUp.vue'
import WelcomeAfterSignUp from '../views/accounts/WelcomeAfterSignUp.vue'
import FirstTimerPrayerRequest from '../views/FirstTimerPrayerRequest.vue'

import HomeView from '../views/HomeView.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

// User Side
import UserDashboard from '../views/User/Dashboard.vue'
import UserRecordings from '../views/User/Recordings.vue'
import UserTestimonies from '../views/User/Testimonies.vue'
import UserMyTestimonies from '../views/User/MyTestimony.vue'
import UserTestimonyDetail from '../views/User/TestimonyDetail.vue'
import UserContactInfo from '../views/User/ContactInfo.vue'
import UserTitheAndOffering from '../views/User/TitheAndOfferings.vue'
import UserSowASeed from '../views/User/SowASeed.vue'
import UserAddNewPrayerRequest from '../views/User/AddNewPrayerRequest.vue'
import UserDashboardPrayerRequest from '../views/User/DashboardPrayerRequests.vue'
import UserFirstTimerPrayerRequest from '../views/User/PrayerRequest.vue'
import UserPrayerRequestInfo from '../views/User/PrayerRequestInfo.vue'

import UserAllActivePrayers from '../views/User/AllActivePrayers.vue'
import UserAllAnsweredPrayers from '../views/User/AllAnsweredPrayers.vue'
import UserPrayerSubmitted from '../views/User/PrayerSubmitted.vue'

import UserCounselling from "../views/User/UserCounselling.vue"
import UserBookedCounselling from "../views/User/UserBookedCounselling.vue"
import UserAvailableCounselling from "../views/User/UserAvailableCounselling.vue"

// Admin Side
import AdminDashboard from "../views/Admin/AdminDashboard.vue"
import AdminPrayerRequest from "../views/Admin/AdminPrayerRequest.vue"
import AdminPrayerRequestInfo from "../views/Admin/AdminPrayerRequestInfo.vue"
import AdminPrayerRequestFeedback from "../views/Admin/AdminPrayerRequestFeedback.vue"
import AdminTestimonies from "../views/Admin/AdminTestimonies.vue"
import AdminTestimonyDetails from "../views/Admin/AdminTestimonyInfo.vue"
import AdminMembers from "../views/Admin/AdminMembers.vue"
import AdminMemberDetails from "../views/Admin/AdminMemberDetail.vue"
import AdminDevotionals from "../views/Admin/AdminDevotional.vue"
import AdminAddDevotionals from "../views/Admin/AdminAddDevotional.vue"
import AdminViewDevotional from "../views/Admin/AdminDevotionalDetail.vue"
import AdminRoles from "../views/Admin/AdminRoles.vue"
import AdminRoleDetail from "../views/Admin/AdminRoleInfo.vue"
import AdminPrayerMeetings from "../views/Admin/AdminPrayerMeetings.vue"
import AdminAddPrayerMeetings from "../views/Admin/AdminAddPrayerMeetings.vue"
import AdminPrayerMeetingDetails from "../views/Admin/AdminPrayerMeetingDetails.vue"

import AdminCounselling from "../views/Admin/AdminCounselling.vue"
import AdminAddCounselling from "../views/Admin/AdminAddCounselling.vue"
import AdminCounsellingDetail from "../views/Admin/AdminCounsellingDetail.vue"
import AdminRecordings from "../views/Admin/AdminRecordings.vue"
import AdminRecordingDetail from "../views/Admin/AdminRecordingDetail.vue"
import AdminSettings from "../views/Admin/AdminSettings.vue"
import AdminAnnouncement from "../views/Admin/AdminAnnouncement.vue"

import AgoraVideoCall from "../views/AgoraVideoCall.vue"

import UserCounsellingVideo from '../views/User/CounsellingVideoSession.vue'


import DeleteProfile from "../views/DeleteProfile.vue"

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  {
    path: '/meeting',
    name: 'agora',
    component: AgoraVideoCall,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  // Accounts Section
  {
    path: '/delete-account',
    name: 'DeleteProfile',
    component: DeleteProfile,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  {
    path: '/accounts/birthday',
    name: 'Birthday',
    component: Birthday,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/accounts/create-new-password',
    name: 'CreateNewPassword',
    component: CreateNewPassword,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/accounts/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/accounts/login',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  {
    path: '/accounts/Otp',
    name: 'Otp',
    component: Otp,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/accounts/otp-verified',
    name: 'OtpVerified',
    component: OtpVerified,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/accounts/Profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/accounts/sign-up',
    name: 'UserSignUp',
    component: UserSignUp,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  {
    path: '/first-timer-prayer-request',
    name: 'FirstTimerPrayerRequest',
    component: FirstTimerPrayerRequest,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  {
    path: '/accounts/welcome',
    name: 'WelcomeAfterSignUp',
    component: WelcomeAfterSignUp,
    meta: {
      requiresAuth: false,
      requiresUser: false,
      requiresAdmin: false,
    }
  },

  // User
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/recordings',
    name: 'UserRecordings',
    component: UserRecordings,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/testimonies',
    name: 'UserTestimonies',
    component: UserTestimonies,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/counselling-video-session',
    name: 'UserCounsellingVideo',
    component: UserCounsellingVideo,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/my-testimonies',
    name: 'UserMyTestimonies',
    component: UserMyTestimonies,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/testimonies/:id',
    name: 'UserTestimonyDetail',
    component: UserTestimonyDetail,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/user-counselling',
    name: 'UserCounselling',
    component: UserCounselling,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/booked-sessions',
    name: 'UserBookedCounselling',
    component: UserBookedCounselling,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/available-sessions',
    name: 'UserAvailableCounselling',
    component: UserAvailableCounselling,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/contact-info',
    name: 'UserContactInfo',
    component: UserContactInfo,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/tithe-and-offering',
    name: 'UserTitheAndOffering',
    component: UserTitheAndOffering,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/sow-seed',
    name: 'UserSowASeed',
    component: UserSowASeed,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/add-new-prayer-request',
    name: 'UserAddNewPrayerRequest',
    component: UserAddNewPrayerRequest,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/prayer-request',
    name: 'UserDashboardPrayerRequest',
    component: UserDashboardPrayerRequest,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/first-prayer-request',
    name: 'UserFirstTimerPrayerRequest',
    component: UserFirstTimerPrayerRequest,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/prayer-request-info/:id',
    name: 'UserPrayerRequestInfo',
    component: UserPrayerRequestInfo,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/all-active-prayers',
    name: 'UserAllActivePrayers',
    component: UserAllActivePrayers,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/all-answered-prayers',
    name: 'UserAllAnsweredPrayers',
    component: UserAllAnsweredPrayers,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/prayer-submitted',
    name: 'UserPrayerSubmitted',
    component: UserPrayerSubmitted,
    meta: {
      requiresAuth: true,
      requiresUser: true
    }

  },


  // Admin
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-prayer-request',
    name: 'AdminPrayerRequest',
    component: AdminPrayerRequest,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-prayer-request/:id',
    name: 'AdminPrayerRequestInfo',
    component: AdminPrayerRequestInfo,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-prayer-request-feedback',
    name: 'AdminPrayerRequestFeedback',
    component: AdminPrayerRequestFeedback,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-members',
    name: 'AdminMembers',
    component: AdminMembers,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-member/:id',
    name: 'AdminMemberDetails',
    component: AdminMemberDetails,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-testimonies',
    name: 'AdminTestimonies',
    component: AdminTestimonies,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-testimony/:id',
    name: 'AdminTestimonyDetails',
    component: AdminTestimonyDetails,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-devotionals',
    name: 'AdminDevotionals',
    component: AdminDevotionals,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-add-devotional',
    name: 'AdminAddDevotionals',
    component: AdminAddDevotionals,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-view-devotional',
    name: 'AdminViewDevotional',
    component: AdminViewDevotional,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-roles',
    name: 'AdminRoles',
    component: AdminRoles,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin/:id',
    name: 'AdminRoleDetail',
    component: AdminRoleDetail,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-prayer-meetings',
    name: 'AdminPrayerMeetings',
    component: AdminPrayerMeetings,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/admin-add-prayer-meeting',
    name: 'AdminAddPrayerMeetings',
    component: AdminAddPrayerMeetings,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-prayer-meeting',
    name: 'AdminPrayerMeetingDetails',
    component: AdminPrayerMeetingDetails,
    meta: {
      requiresAuth: false,
      requiresAdmin: false,
    }
  },
  {
    path: '/admin-counselling',
    name: 'AdminCounselling',
    component: AdminCounselling,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-add-counselling',
    name: 'AdminAddCounselling',
    component: AdminAddCounselling,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-counselling/:id',
    name: 'AdminCounsellingDetail',
    component: AdminCounsellingDetail,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
  ,
  {
    path: '/admin-recording',
    name: 'AdminRecordings',
    component: AdminRecordings,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
  ,
  {
    path: '/admin-recording/:id',
    name: 'AdminRecordingDetail',
    component: AdminRecordingDetail,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
  ,
  {
    path: '/admin-setting',
    name: 'AdminSettings',
    component: AdminSettings,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/admin-annoucement',
    name: 'AdminAnnouncement',
    component: AdminAnnouncement,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  }
]

// Helper Authenticaton & Role Functions

const isAdmin = () => {
 
  return JSON.parse(localStorage.getItem("user"))?.user_type == "super_admin"
}
const isMember = () => {
  return JSON.parse(localStorage.getItem("user"))?.user_type == "member"
}
const isAuthenticated = () => {
  return localStorage.getItem("token") !== null
}

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next('/accounts/login');
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    return;
   
  }
  if (to.meta.requiresAdmin && !isAdmin()) {
    next('/accounts/login');
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    return;
  
  }
  if (to.meta.requiresUser && !isMember()) {
    next('/accounts/login');
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    return;
  }
  next();
})

export default router
