import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// toast
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useAuthStore } from "./stores/auth";
import { createPinia } from 'pinia'


const pinia = createPinia();
const app = createApp(App);


app.use(pinia);

const authStore = useAuthStore();
authStore.initAuthStore();

app.use(router)
app.use(ToastPlugin);
app.use(VueTelInput)
// app.use(VueApexCharts)
app.mount('#app')
