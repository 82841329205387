<template>
  <div>
    <AdminLayout Title="Testimonies" :isRequired="false">
      <div class="container">
        <DashboardSkeleton class="pt-6" v-if="loading" />

        <div class="row pt-6" v-else>
          <div class="col-12 col-lg-4">
            <div class="row bg-white py-3 px-1 rounded-2 b-color">
              <div class="col-12">
                <div
                  class="card p-2 border-0 bg-card2 d-flex justify-content-between flex-column mt-2"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Accepted Testimonies</p>
                    <img
                      src="../../assets/admin-prayerreq2.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">
                      {{ testimoniesCounts?.counts?.accepted ?? "0" }}
                    </h5>
                  </div>
                </div>
                <div
                  class="card p-2 border-0 bg-card3 d-flex justify-content-between flex-column mt-2"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Pending Testimonies</p>
                    <img
                      src="../../assets/hour-glass.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">
                      {{ testimoniesCounts?.counts?.pending ?? "0" }}
                    </h5>
                  </div>
                </div>
                <div
                  class="card p-2 border-0 bg-card1 d-flex justify-content-between flex-column mt-2"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <p class="text-body-secondary">Rejected Testimonies</p>
                    <img
                      src="../../assets/admin-prayerreq.png"
                      class="img-fluid d-flex align-self-start"
                      width="50"
                    />
                  </div>
                  <div class="d-flex">
                    <h5 class="mt-3 fw-semibold">
                      {{ testimoniesCounts?.counts?.rejected ?? "0" }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="bg-white p-2 rounded-2 b-color">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-12 col-lg-6 d-flex justify-content-end">
                  <input
                    type="text"
                    placeholder="Enter to search"
                    class="form-control p-2 ms-0 w-100"
                  />
                </div>
                <div class="col-12 col-lg-6 d-flex justify-content-end">
                  <div class="d-flex p-1 mb-1">
                    <button
                      class="m-1 btn-accent2 p-1"
                      @click.prevent="allTestimonies"
                    >
                      All
                    </button>
                    <button
                      class="m-1 btn-accent2 p-1"
                      @click.prevent="acceptedTestimonies"
                    >
                      Accepted
                    </button>
                    <button
                      class="m-1 btn-accent2 p-1"
                      @click.prevent="pendingTestimonies"
                    >
                      Pending
                    </button>
                    <button
                      class="m-1 btn-accent2 p-1"
                      @click.prevent="rejectedTestimonies"
                    >
                      Rejected
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive">

              <table class="table mt-3 rounded-2">
                <thead class="bg-grey">
                  <tr class="bg-grey">
                    <td class="text-body-secondary">Testimony Subject</td>
                    <td class="text-body-secondary">Member's Name</td>
                    <td class="text-center text-body-secondary">Status</td>
                    <td class="text-end text-body-secondary">Date Submitted</td>
  
                    <td class="text-end text-body-secondary">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="testimonies.length == 0">
                    <td class="text-semibold text-center">No Testimonies</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="testimony in selectedTestimonies"
                    :key="testimony.id"
                  >
                    <td>{{ testimony?.testimony_category ?? "No Subject" }}</td>
                    <td>
                      {{ testimony?.user?.first_name }}
                      {{ testimony?.user?.last_name }}
                    </td>
                    <td class="text-center">
                      <button
                        class="text-capitalize fw-medium"
                        :class="{
                          'btn-pending': testimony?.status == 'pending',
                          'btn-active': testimony?.status !== 'pending',
                        }"
                      >
                        {{ testimony?.status }}
                      </button>
                    </td>
                    <td class="text-end">
                      {{ getFormattedDate(testimony?.created_at) }}
                    </td>
                    <td class="text-end">
                      <router-link
                        :to="{
                          name: 'AdminTestimonyDetails',
                          params: { id: testimony?.id },
                        }"
                        class="btn-active text-decoration-none"
                        ><i class="bi bi-eye-fill ms-1"></i
                      ></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <nav aria-label="Page navigation" v-if="links.length != 0">
              <ul class="pagination justify-content-end">
                <!-- Previous button -->
                <li
                  class="page-item"
                  :class="{ disabled: !links[0]?.url }"
                  @click="changePage(currentPage - 1)"
                >
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>

                <!-- Page links -->
                <li
                  class="page-item"
                  v-for="link in links.slice(1, -1)"
                  :key="link.label"
                  :class="{ active: link?.active }"
                  @click="changePageFromUrl(link?.url)"
                >
                  <a class="page-link" href="#">{{ link?.label }}</a>
                </li>

                <!-- Next button -->
                <li
                  class="page-item"
                  :class="{ disabled: !links[links.length - 1]?.url }"
                  @click="changePage(currentPage + 1)"
                >
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";
import DashboardSkeleton from "@/components/DashboardSkeleton.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import { getFormattedDate, getFormattedAmount } from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const testimoniesCounts = ref({});
const testimonies = ref([]);

const loading = ref(false);

const selectedTestimonies = ref([]);

const currentPage = ref(1);
const totalPages = ref(0);
const paginator = ref({});
const links = ref([]);

const changePageFromUrl = (url) => {
  if (url) {
    const urlParams = new URL(url).searchParams;
    const page = urlParams.get("page");
    initTestimonies(parseInt(page, 10));
  }
};

const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    initTestimonies(page);
  }
};

const acceptedTestimoniesData = computed(() => {
  return testimonies.value.filter((prayer) => prayer?.status == "accepted");
});
const pendingTestimoniesData = computed(() => {
  return testimonies.value.filter((prayer) => prayer?.status == "pending");
});
const rejectedTestimoniesData = computed(() => {
  return testimonies.value.filter((prayer) => prayer?.status == "rejected");
});

function acceptedTestimonies() {
  selectedTestimonies.value = acceptedTestimoniesData.value;
}
function allTestimonies() {
  selectedTestimonies.value = testimonies.value;
}
function pendingTestimonies() {
  selectedTestimonies.value = pendingTestimoniesData.value;
}
function rejectedTestimonies() {
  selectedTestimonies.value = rejectedTestimoniesData.value;
}

async function initTestimonies(page = 1) {
  loading.value = true;
  try {
    const _testimonies = await axios.get(`/testimonies?page=${page}`);
    const _testimoniesCount = await axios.get(`/admin/testimony-counts`);
    testimonies.value = _testimonies.data.data;
    selectedTestimonies.value = testimonies.value;

    paginator.value = _testimonies.data.meta;
    links.value = paginator.value.links;
    // gettingNextPage.value = false;
    currentPage.value = paginator.value.current_page;
    totalPages.value = paginator.value.total;
    testimoniesCounts.value = _testimoniesCount.data.data;
    loading.value = false;
  } catch (error) {
    utils.showToast(catchAxiosExceptions(error).msg, "error");
    loading.value = false;
  }
}

onMounted(() => {
  initTestimonies(currentPage.value);
});
</script>
<style scoped>
/* *{
  outline: 1px solid red;
} */

.btn-accent1 {
  background-color: rgba(234, 241, 255, 1);
  color: rgba(24, 41, 101, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}
.btn-accent2 {
  background-color: rgba(246, 246, 246, 1);
  color: rgba(96, 96, 96, 1);
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.5rem !important;
}

.filter {
  border: 1px solid rgba(218, 229, 239, 1);
  backdrop-filter: blur(20px);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.02);
  border-radius: 9px;
}
.pt-6 {
  padding-top: 6rem !important;
}

.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}
.btn-pending {
  border: none !important;
  color: #f7c708 !important;
  background-color: #fff9e2 !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.bg-white {
  background-color: #fff;
}

.bg-card1 {
  background-color: #ffeded;
}
/* .bg-card2 {
  background-color: #fdf2ff;
} */
.bg-card3 {
  background-color: #fff9e2;
}
.bg-card2 {
  background-color: #e1ffea !important;
}

.btn-accepted {
  border: none !important;
  color: green !important;
  background-color: rgba(54, 202, 54, 0.453) !important;
  padding: 0.3rem !important;
  border-radius: 5px;
}

.b-color {
  border: 1px solid #ddedff !important;
}
</style>
