<template>
  <div>
    <AdminLayout Title="Members" :isRequired="false">
      <TableSkeletonLoader class="pt-6" v-if="loading2" />

      <div class="container pt-6" v-else>
        <router-link
          :to="{ name: 'AdminMembers' }"
          class="text-decoration-none text-body-secondary ms-2"
        >
          <img
            src="../../assets/back.png"
            alt=""
            class="img-fluid me-2 mb-1"
          />Back</router-link
        >
        <div class="row mt-4 g-2">
          <div class="col-12 col-lg-5">
            <div class="bg-card1 p-3 rounded-3">
              <div class="d-flex align-items-center">
                <img
                  :src="
                    memberDetail?.photo
                      ? memberDetail?.photo
                      : require('@/assets/dummy-avatar-logo.svg')
                  "
                  alt=""
                  class="img-fluid me-2 d-none d-md-flex"
                  width="50"
                  height="50"
                />
                <div class="ms-3">
                  <h5 class="mb-0">
                    {{ memberDetail?.first_name }} {{ memberDetail?.last_name }}
                  </h5>
                  <p class="text-body-secondary mt-0">
                    {{ memberDetail?.email }}
                  </p>
                </div>
              </div>
              <div class="d-flex mt-3 row g-2">
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Date Joined</small>
                    <p class="fw-semibold mt-3">
                      {{ getFormattedDateNumber(memberDetail?.created_at) }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary">Prayer Requests</small>
                    <p class="fw-semibold mt-3">
                      {{ memberDetail?.prayer_requests }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-lg-4 border-0">
                  <div class="bg-white p-2 rounded-2">
                    <small class="text-body-secondary d-block mb-1"
                      >Testimonies</small
                    >
                    <p class="fw-semibold mt-3 pt-1">
                      {{ memberDetail?.testimonies?.length }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <div class="card p-3 b-color">
              <div class="card bg-tabs p-0 p-lg-2 border-0">
                <ul class="nav nav-pills mb-3" role="tablist">
                  <li class="nav-item ms-lg-3" role="presentation">
                    <button
                      class="nav-link active rounded-pill"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Prayer Requests
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link rounded-pill"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    >
                      Offerings
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link rounded-pill"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    >
                      Testimonies
                    </button>
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active p-2"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabindex="0"
                  >
                    <div
                      class="card p-card2 b-color p-3 mb-2"
                      v-if="memberDetail?.prayer_points?.length == 0"
                    >
                      <p class="text-center fw-semilight">No Prayer Request</p>
                    </div>
                    <div
                      class="card p-card2 b-color p-3 mb-2"
                      v-for="(
                        prayerRequest, index
                      ) in memberDetail.prayer_points"
                      :key="index"
                    >
                      <div class="d-flex justify-content-between">
                        <h5>{{ prayerRequest?.subject }}</h5>
                        <p class="text-body-secondary">
                          {{ getFormattedDate(prayerRequest?.created_at) }}
                        </p>
                      </div>
                      <p class="text-body-secondary mt-0">
                        {{ prayerRequest?.conversation?.messages[0]?.message }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade p-3"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabindex="0"
                  >
                    <div
                      class="card p-card1 b-color p-3 mb-2"
                      v-if="memberDetail?.offerings?.length == 0"
                    >
                      <p class="text-center fw-semilight">No Offerings</p>
                    </div>
                    <div
                      class="card p-card1 b-color p-3 mb-2"
                      v-for="(offering, index) in memberDetail.offerings"
                      :key="index"
                    >
                      <div class="d-flex justify-content-between">
                        <h5>{{ offering }}</h5>
                        <p class="text-body-secondary">
                          {{ offering }}
                        </p>
                      </div>
                      <p class="text-body-secondary mt-0">
                        <!-- {{ prayerRequest?.conversation?.messages[0]?.message }} -->
                        {{ offering }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade p-3"
                    id="contact-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabindex="0"
                  >
                    <div
                      class="card b-color p-3 mb-2"
                      v-if="memberDetail?.testimonies?.length == 0"
                    >
                      <p class="text-center fw-semilight">No Testimony</p>
                    </div>
                    <div
                      class="card p-card1 b-color p-3 mb-2"
                      v-for="(testimony, index) in memberDetail.testimonies"
                      :key="index"
                    >
                      <div class="d-flex justify-content-between">
                        <h5>{{ testimony?.testimony_category }}</h5>
                        <p class="text-body-secondary">
                          {{ getFormattedDate(testimony?.created_at) }}
                        </p>
                      </div>
                      <p class="text-body-secondary mt-0 text-capitalize">
                        <!-- {{ prayerRequest?.conversation?.messages[0]?.message }} -->
                        {{ testimony?.content }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import AdminLayout from "@/components/Layouts/AdminLayout.vue";

import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import TableSkeletonLoader from "@/components/TableSkeletonLoader.vue";

import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";

import utils from "@/Plugins/utils";
import axios from "@/Plugins/axios";

const loading2 = ref(false);

const { id } = useRoute().params;

const memberDetail = ref({});

function getMemberDetail() {
  loading2.value = true;
  axios
    .get(`/admin/members/${id}`)
    .then((response) => {
      memberDetail.value = response.data.data;
      loading2.value = false;
    })
    .catch((error) => {
      utils.showToast(catchAxiosExceptions(error).msg, "error");
      loading2.value = false;
    });
}

onMounted(() => {
  getMemberDetail();
});
</script>
<style scoped>
/* * {
  outline: 1px solid red;
} */
.updated {
  background-color: rgba(225, 243, 255, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: rgba(10, 64, 194, 1) !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.header-text2 {
  font-weight: 900;
  font-size: 1.8rem;
}
.pt-6 {
  padding-top: 6rem !important;
}
.bg-card1 {
  background-color: #e4f5ff !important;
}
.btn-active {
  border: none !important;
  color: #0077ff !important;
  background-color: #eaf5ff !important;
  padding: 0.2rem !important;
  border-radius: 5px;
}

.signInBtn {
  background-color: rgba(10, 64, 194, 1) !important;
  font-weight: 600 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.signOutBtn {
  background-color: #f24c35 !important;
  font-weight: 500 !important;
  border: none;
  color: #fff !important;

  font-size: 1rem !important;
  padding: 1rem 1.5rem;
  border-radius: 9px;
}

.bg-delete {
  background-color: #fff4f4 !important;
}

.b-color {
  border: 1px solid #ddedff !important;
}

.p-card1 {
  background-color: rgba(248, 248, 248, 1) !important;
}

.p-card2 {
  background-color: rgba(237, 255, 227, 1);
}

/* Extra small devices (phones, 480px and up) */
@media only screen and (max-width: 768px) {
  /* *{
    outline: 1px solid red;
  } */

  .nav {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
  }

  .nav-item {
    width: 100% !important;
  }

  .nav-link {
    color: #131313 !important;
    width: 100% !important;
    padding: 0.8rem 5rem !important;
    margin: 0.7rem !important;
    background-color: rgba(230, 243, 252, 1) !important;
  }
  .nav-link.active {
    color: #fff !important;
    background-color: rgba(10, 64, 194, 1) !important;
    padding: 0.8rem 5rem !important;
    margin: 0.7rem !important;
  }
}

/* Large devices (desktops, 1200px and up) */
@media (min-width: 1200px) {
  .nav-link {
    color: #131313 !important;
    padding: 0.8rem 2rem !important;
    margin: 0 0.6rem !important;
    background-color: rgba(230, 243, 252, 1) !important;
  }
  .nav-link.active {
    color: #fff !important;
    background-color: rgba(10, 64, 194, 1) !important;
    padding: 0.8rem 2rem !important;
  }
}
</style>
