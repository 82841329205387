<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-dashboard-nav fixed-top shadow-sm">
      <div class="container">
        <a
          class="navbar-brand"
          href="https://propheticprayerdepot.org"
          target="_blank"
          ><img
            src="../assets/logoP.jpg"
            alt=""
            class="rounded-circle img-fluid"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasTop"
          aria-controls="offcanvasTop"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="offcanvas offcanvas-top"
          tabindex="-1"
          id="offcanvasTop"
          aria-labelledby="offcanvasTopLabel"
        >
          <div class="offcanvas-header" data-bs-theme="dark">
            <h5 class="offcanvas-title text-white" id="offcanvasTopLabel">
              <a class="navbar-brand" href="#"
                ><img
                  src="../assets/logoP.jpg"
                  alt=""
                  class="rounded-circle img-fluid"
              /></a>
            </h5>
            <button
              type="button"
              class="btn-close text-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            <ul
              class="navbar-nav justify-content-center align-items-center mx-auto flex-grow-1 pe-3 d-none d-lg-flex"
            >
              <li class="nav-item">
                <router-link
                  class="nav-link mx-2"
                  aria-current="page"
                  active-class="active"
                  :to="{ name: 'UserDashboard' }"
                  ><img
                    src="../assets/home-2.png"
                    alt=""
                    class="img-fluid me-1"
                  />Home</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link mx-2"
                  active-class="active"
                  :to="{ name: 'UserDashboardPrayerRequest' }"
                  ><img
                    src="../assets/message-notif.png"
                    alt=""
                    class="img-fluid me-1"
                  />Prayer Requests</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link mx-2"
                  active-class="active"
                  :to="{ name: 'UserRecordings' }"
                  ><img
                    src="../assets/video.png"
                    alt=""
                    class="img-fluid me-1"
                  />Recordings</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link ms-2 me-3"
                  active-class="active"
                  :to="{ name: 'UserTitheAndOffering' }"
                  ><img
                    src="../assets/card-pos.png"
                    alt=""
                    class="img-fluid me-1"
                  />Offerings</router-link
                >
              </li>
              <li>
                <div class="dropdown more dropdown-center">
                  <a
                    href="#"
                    class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link py-0"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/more.png"
                      alt=""
                      class="img-fluid me-1"
                    />
                    <span class="nav-link">More</span>
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-light text-small shadow w-100 min-width-md-200px"
                    style=""
                  >
                    <li class="w-100">
                      <div class="list-group list-group-flush my-0 px-3">
                        <router-link
                          :to="{ name: 'UserProfile' }"
                          class="list-group-item d-flex align-items-center p-2 border-bottom text-black"
                          style="cursor: pointer"
                        >
                          <img
                            src="../assets/profile2.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <span class="d-block">
                            <span class="mb-0 d-block fw-bold">Profile</span>
                            <span
                              class="text-body-secondary m-0 d-block"
                              style="font-size: 0.95rem"
                            >
                              Manage your personal information
                            </span>
                          </span>
                          <img
                            src="../assets/right-gray.png"
                            alt=""
                            class="ms-auto"
                          />
                        </router-link>
                        <router-link
                          :to="{ name: 'UserCounselling' }"
                          class="list-group-item d-flex align-items-center p-2 border-bottom text-black"
                          style="cursor: pointer"
                        >
                          <img
                            src="../assets/counselling-chatbox.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <span class="d-block">
                            <span class="mb-0 d-block fw-bold"
                              >Counselling Sessions</span
                            >
                            <span
                              class="text-body-secondary m-0 d-block"
                              style="font-size: 0.95rem"
                            >
                              Book Counselling Sessions
                            </span>
                          </span>
                          <img
                            src="../assets/right-gray.png"
                            alt=""
                            class="ms-auto"
                          />
                        </router-link>
                        <router-link
                          :to="{ name: 'UserMyTestimonies' }"
                          class="list-group-item d-flex align-items-center p-2 border-bottom text-black"
                          style="cursor: pointer"
                        >
                          <img
                            src="../assets/moreTestimonies.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <span class="d-block">
                            <span class="mb-0 fw-bold d-block"
                              >My Testimonies</span
                            >
                            <span
                              class="text-body-secondary m-0 d-block"
                              style="font-size: 0.95rem"
                            >
                              Share your goodnews with others
                            </span>
                          </span>
                          <img
                            src="../assets/right-gray.png"
                            alt=""
                            class="ms-auto"
                          />
                        </router-link>
                        <router-link
                          to="#"
                          class="list-group-item d-flex align-items-center p-2 border-bottom text-black"
                          style="cursor: pointer"
                          @click.prevent="triggerUpdatePassword"
                        >
                          <img
                            src="../assets/morePassword.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <span>
                            <span class="mb-0 fw-bold d-block"
                              >Update Password</span
                            >
                            <span
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Stay updated with the latest news and alerts.
                            </span>
                          </span>
                          <img
                            src="../assets/right-gray.png"
                            alt=""
                            class="ms-auto"
                          />
                        </router-link>
                        <router-link
                          class="list-group-item d-flex align-items-center p-2 border-bottom text-black"
                          style="cursor: pointer"
                          :to="{ name: 'UserContactInfo' }"
                        >
                          <img
                            src="../assets/moreContact.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <span class="d-block">
                            <span class="mb-0 d-block fw-bold"
                              >Contact Information</span
                            >
                            <span
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Contact or reach out to us today.
                            </span>
                          </span>
                          <img
                            src="../assets/right-gray.png"
                            alt=""
                            class="ms-auto"
                          />
                        </router-link>
                        <router-link
                          to="#"
                          class="list-group-item d-flex align-items-center p-2 border-bottom text-black"
                          style="cursor: pointer"
                          @click.prevent="triggerSignOut"
                        >
                          <img
                            src="../assets/moreSignOut.png"
                            alt=""
                            class="me-3 img-fluid"
                            width="45"
                          />
                          <div>
                            <span class="mb-0 d-block fw-bold">Sign-out</span>
                            <span
                              class="text-body-secondary m-0"
                              style="font-size: 0.95rem"
                            >
                              Sign Out of Account
                            </span>
                          </div>
                          <img
                            src="../assets/right-gray.png"
                            alt=""
                            class="ms-auto"
                          />
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul class="navbar-nav pe-3 d-block d-lg-none">
              <li class="nav-item my-2">
                <router-link
                  class="nav-link mx-2"
                  aria-current="page"
                  :to="{ name: 'UserDashboard' }"
                  ><img
                    src="../assets/home-2.png"
                    alt=""
                    class="img-fluid me-1"
                  />Home</router-link
                >
              </li>
              <li class="nav-item my-2">
                <router-link
                  class="nav-link mx-2"
                  :to="{ name: 'UserDashboardPrayerRequest' }"
                  ><img
                    src="../assets/message-notif.png"
                    alt=""
                    class="img-fluid me-1"
                  />Prayer Requests</router-link
                >
              </li>
              <li class="nav-item my-2">
                <router-link
                  class="nav-link mx-2"
                  :to="{ name: 'UserRecordings' }"
                  ><img
                    src="../assets/video.png"
                    alt=""
                    class="img-fluid me-1"
                  />Recordings</router-link
                >
              </li>
              <li class="nav-item my-2">
                <router-link
                  class="nav-link ms-2 me-3"
                  :to="{ name: 'UserTitheAndOffering' }"
                  ><img
                    src="../assets/card-pos.png"
                    alt=""
                    class="img-fluid me-1"
                  />Offerings</router-link
                >
              </li>

              <li class="nav-item">
                <div class="accordion-item">
                  <h4 class="accordion-header" id="headingOne">
                    <button
                      class="nav-link ms-2 py-2 px-1"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <img
                        src="../assets/more.png"
                        alt=""
                        class="img-fluid me-1"
                      />
                      More
                    </button>
                  </h4>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse ms-3 pt-2"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <ul class="list-unstyled">
                      <li>
                        <router-link
                          :to="{ name: 'UserProfile' }"
                          class="nav-link text-decoration-none d-flex align-items-center"
                          >Profile
                          <i class="bi bi-caret-right-fill nav-link ms-2"></i
                        ></router-link>
                      </li>
                      <li>
                        <router-link
                          :to="{ name: 'UserProfile' }"
                          class="nav-link text-decoration-none d-flex align-items-center"
                          >Counselling Sessions
                          <i class="bi bi-caret-right-fill nav-link ms-2"></i
                        ></router-link>
                      </li>
                      <li class="">
                        <router-link
                          :to="{ name: 'UserTestimonies' }"
                          class="nav-link text-decoration-none d-flex align-items-center"
                          >My Testimonies
                          <i class="bi bi-caret-right-fill nav-link ms-2"></i
                        ></router-link>
                      </li>
                      <li class="">
                        <router-link
                          to="#"
                          @click.prevent="triggerUpdatePassword"
                          class="nav-link text-decoration-none d-flex align-items-center"
                          >Update Password
                          <i class="bi bi-caret-right-fill nav-link ms-2"></i
                        ></router-link>
                      </li>
                      <li class="">
                        <router-link
                          :to="{ name: 'UserContactInfo' }"
                          class="nav-link text-decoration-none d-flex align-items-center"
                          >Contact Information
                          <i class="bi bi-caret-right-fill nav-link ms-2"></i
                        ></router-link>
                      </li>
                      <li class="">
                        <router-link
                          to="#"
                          @click.prevent="triggerSignOut"
                          class="nav-link text-decoration-none d-flex align-items-center"
                          >Sign Out
                          <i class="bi bi-caret-right-fill nav-link ms-2"></i
                        ></router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>

            <div class="dropdown more dropstart mt-1 me-1 d-none d-lg-flex">
              <a
                href="#"
                class="d-flex align-items-center text-decoration-none dropdown-toggle nav-link py-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="../assets/bell-white.png"
                  alt=""
                  class="img-fluid me-1"
                />
              </a>

              <ul
                class="dropdown-menu dropdown-menu-light text-small shadow w-100 min-width-md-400px mt-5"
                style="max-height: 300px; overflow: auto"
              >
                <li class="w-100">
                  <p class="mb-2 ms-3 header-text">Notification</p>
                </li>
                <li class="w-100" v-if="notifications.length == 0">
                  <span class="ms-4"> No Notification</span>
                </li>
                <li class="w-100">
                  <ul class="list-group list-group-flush my-0 px-3">
                    <li
                      class="list-group-item my-2 d-flex justify-content-between p-2 border-bottom"
                      style="cursor: pointer"
                      v-for="(notification, i) in notifications"
                      v-show="i < 3"
                      :key="notification?.id"
                    >
                      <div class="d-flex">
                        <div>
                          <h6 class="mb-0 d-flex flex-wrap">
                            {{ notification?.data?.title }}
                          </h6>
                          <p
                            class="text-body-secondary m-0"
                            style="font-size: 0.95rem"
                          >
                            {{
                              notification?.data?.description?.slice(0, 40)
                            }}...
                          </p>
                        </div>
                      </div>
                      <span class="ps-3 text-primary">{{
                        notification?.data?.recurring_start_time
                      }}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="dropdown d-flex d-none d-lg-flex">
              <!-- <img src="../assets/alarm.png" alt="" class="img-fluid me-2" /> -->
              <a
                href="#"
                class="d-flex align-items-center text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  :src="
                    user?.photo
                      ? user.photo
                      : require('@/assets/dummy-avatar-logo copy.svg')
                  "
                  alt=""
                  class="img-fluid rounded-circle me-2"
                  width="30"
                  height="30"
                />

                <span class=""
                  >{{ user?.first_name }} {{ user?.last_name }}
                </span>
              </a>
              <ul
                class="dropdown-menu dropdown-menu-light text-small shadow d-none"
                style=""
              >
                <li>
                  <a class="dropdown-item text-dark" href="#">Profile</a>
                </li>
                <!-- <li><hr class="dropdown-divider" /></li> -->
                <!-- <li>
                    <a class="dropdown-item text-dark" href="#">Sign out</a>
                  </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import {
  getFormattedDate,
  getFormattedDateNumber,
  getFormattedAmount,
} from "@/Utils/handleResponse";
import axios from "@/Plugins/axios";
import { catchAxiosExceptions } from "@/Plugins/handleExceptions";
import utils from "@/Plugins/utils";

const { user } = useAuthStore();
const emit = defineEmits(["signOutEvent", "updatePassword"]);

const notifications = ref([]);
function triggerSignOut(data) {
  emit("signOutEvent", data);
}
function triggerUpdatePassword(data) {
  emit("updatePassword", data);
}

function getNotification() {
  if(!user) return;
  axios
    .get(`/notifications`)
    .then((response) => {
      notifications.value = response.data.data ?? [];
    })
    .catch((error) => {
      catchAxiosExceptions(error);
      utils.showToast(catchAxiosExceptions(error).msg, "error");
    });
}

onMounted(() => {
  getNotification();
});
</script>

<style scoped>
/* * {
  outline: 1px solid red;
} */

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #303030;
}

.accordion-button {
  background-color: transparent !important;
  color: #fff !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-repeat: no-repeat;
  display: none !important;
  background-size: 1.5rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
  background: none !important;
  /* transform: rotate(-200deg) !important; */
}

.navbar-toggler {
  outline: none !important;
  border: 1px solid #fff !important;
  /* background-color: #fff !important; */
}

.navbar-toggler-icon {
  background-image: url("../assets/list.svg") !important;
}

.header-text {
  font-weight: 300;
  font-size: 1.4rem;
}
.min-width-md-200px {
  min-width: 400px;
}
.min-width-md-400px {
  min-width: 500px;
}

@media (max-width: 768px) {
  .min-width-md-200px {
    width: 100%;
  }
  .min-width-md-400px {
    width: 100%;
  }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 55vh !important;
    max-height: 100%;
    /* border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color); */
    /* transform: translateY(-100%); */
  }
}

.offcanvas {
  background-color: rgba(10, 64, 194, 1);
}
.navbar {
  background-image: url("../assets/Dots.png") !important;
}

.nav-link,
.dropdown-toggle {
  color: rgba(207, 207, 207, 1);
}

.dropdown a {
  color: #fff;
  /* background-color: rgba(5, 51, 162, 1); */
}
.nav-link.active {
  background-color: rgba(5, 51, 162, 1) !important;
  color: #fff !important;
  border-radius: 6px !important;
}

.nav-link:hover {
  background-color: rgba(5, 51, 162, 1);
  border-radius: 6px;
  color: #fff;
}

.more .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.w-60 {
  width: 60% !important;
}
</style>
